import {
	Button,
	makeStyles,
	Paper,
	Grid,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useEffect, useState } from "react";
import ChipsArray from "./components/ChipArray";
import PropTypes from "prop-types";
import { getCategories, getShippingPrices } from "../utils/dbRequests";

const useStyles = makeStyles((theme) => ({
	root: {
		flexGrow: 1,
	},
	hoursContainer: {
		display: "flex",
		flexDirection: "column",
		justifyContent: "center",
		alignItems: "center",
		// maxWidth: 500,
	},
	paper: {
		padding: theme.spacing(2),
		textAlign: "center",
		color: theme.palette.text.secondary,
	},
	fullWidth: {
		width: "70%",
		margin: 10,
	},
	title: {
		marginBottom: 15,
	},
}));

export default function GeneralSettings({ db }) {
	const classes = useStyles();
	const [initialCategories, setInitialCategories] = useState();
	const [shippingPrices, setShippingPrices] = useState({});

	const updateCategories = (categories) => {
		db.collection("generalData").doc("productCategories").set({
			data: categories,
		});
	};

	const handleShippingPrice = (e) => {
		setShippingPrices((s) => {
			s[e.target.name] = e.target.value;
			return s;
		});
	};

	const updateShippingPrices = () => {
		db.collection("generalData").doc("shippingPrices").set(shippingPrices);
	};

	const init = async () => {
		setInitialCategories(await getCategories(db));
		setShippingPrices(await getShippingPrices(db));
	};

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return (
		<div className={classes.root}>
			<Grid container spacing={3}>
				<Grid item xs={12}>
					<Typography variant="h2">General Settings</Typography>
				</Grid>

				<Grid item xs={6}>
					<Paper className={classes.paper}>
						<Typography className={classes.title} variant="h4">
							Product Categories
						</Typography>

						{initialCategories ? (
							<ChipsArray
								onChange={updateCategories}
								initialChips={initialCategories}
							/>
						) : (
							<div>Loading Categories...</div>
						)}
					</Paper>
				</Grid>
				<Grid item xs={6}>
					<Paper className={[classes.hoursContainer, classes.paper]}>
						<Typography className={classes.title} variant="h4">
							Shipping Prices
						</Typography>
						<TextField
							variant="outlined"
							type="number"
							onChange={handleShippingPrice}
							label="Lima price USD"
							placeholder={shippingPrices.lima}
							name="lima"
							className={classes.fullWidth}
						/>

						<TextField
							variant="outlined"
							onChange={handleShippingPrice}
							type="number"
							name="national"
							placeholder={shippingPrices.national}
							label="National price (province) USD"
							className={classes.fullWidth}
						/>
						<TextField
							variant="outlined"
							type="number"
							onChange={handleShippingPrice}
							name="international"
							placeholder={shippingPrices.international}
							label="International price USD"
							className={classes.fullWidth}
						/>
						<Button
							onClick={updateShippingPrices}
							color="primary"
							variant="contained"
						>
							Save
						</Button>
					</Paper>
				</Grid>
			</Grid>
		</div>
	);
}

GeneralSettings.propTypes = {
	db: PropTypes.object.isRequired,
};
