export const scrollToTop = () => {
  document.body.scrollTop = 0; // For Safari
  document.documentElement.scrollTop = 0; // For Chrome, Firefox, IE and Opera
};

export const getDateAndTime = () => {
  let d = new Date();
  let now = d.toISOString();
  return now;
};

export const getDayMonthYear = () => {
  var today = new Date();

  var strDate = 'Y-m-d'
    .replace('Y', today.getFullYear())
    .replace('m', today.getMonth() + 1)
    .replace('d', today.getDate());
  return strDate;
};

export const tooltipAdded = () => {
  let tooltip = document.getElementsByClassName('item-added-tooltip')[0];
  tooltip.style.opacity = 1;
  setTimeout(() => {
    tooltip.style.opacity = 0;
  }, 2000);
};

export const getTotal = (items, coupon, shipping = 0) => {
  let total = 0;
  Object.keys(items).forEach((id) => {
    let item = items[id];
    total = total + item.price * item.amount;
  });
  if (coupon) {
    total = total * ((100 - coupon.amount) / 100);
  }
  return total + shipping;
};

export const changeCurrency = (amountInUSD, to, rates) => {
  let newAmount = amountInUSD * rates[to];
  let keys = {
    USD: '$',
    PEN: 'S/.',
    EUR: '€',
  };
  return `${keys[to]}${Math.round(newAmount)}`;
};

export const calculatePrice = (price, currency, rates) => {
  switch (currency) {
    case 'USD':
      return `$${price}`;
    case 'PEN':
      return `./S${price}`;
    case 'EUR':
      return `€${price}`;
    default:
      console.log('currency type not valid');
  }
};
