import React from 'react';
import { useState, useEffect } from 'react';
import { db } from '../App';
import { getDateAndTime } from '../utils/utils';
import { makeStyles } from '@material-ui/core/styles';
import TextField from '@material-ui/core/TextField';
import { default as formText } from '../utils/text';

const text = formText.contact.form;

const useStyles = makeStyles((theme) => ({
  root: {
    '& > *': {
      margin: theme.spacing(1.5),
      width: '90vw',
      maxWidth: 500,
    },
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
}));

export const Form = (props) => {
  const [isReady, setIsReady] = useState(false);
  const classes = useStyles();
  const [touched, setTouched] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });
  const [values, setValues] = useState({
    name: undefined,
    email: undefined,
    subject: undefined,
    message: undefined,
  });
  const [errors, setErrors] = useState({
    name: false,
    email: false,
    subject: false,
    message: false,
  });
  const [helperText, setHelperText] = useState({
    name: null,
    email: null,
    subject: null,
    message: null,
  });

  const handleUpload = () => {
    db.collection('contact_info')
      .doc(Math.round(Math.random() * 10000000).toString())
      .set({
        name: values.name,
        email: values.email,
        subject: values.subject,
        message: values.message,
        date_time: getDateAndTime(),
      })
      .then((message) => props.isSent(true))
      .catch((e) => alert('Could not send message'));
  };

  useEffect(() => {
    let errors = validate(values);
    setErrors(errors[0]);
    setHelperText(errors[1]);
    setIsReady(errors[2]);
    // eslint-disable-next-line
  }, [props.lang]);

  const validate = (values) => {
    const errors = {};
    let ready = true;
    const helperText = {};
    const requiredFields = ['name', 'email', 'subject', 'message'];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        ready = false;
        if (touched[field]) {
          errors[field] = true;
          helperText[field] = text[props.lang].required;
        }
      } else {
        errors[field] = false;
        helperText[field] = null;
      }
    });
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = true;
      ready = false;
      helperText.email = text[props.lang].emailInvalid;
    }
    return [errors, helperText, ready];
  };

  const handleChange = (e, valueName) => {
    let newValues = { ...values, [valueName]: e.target.value };
    setTouched({ ...touched, [valueName]: true });
    setValues(newValues);
    let errors = validate(newValues);
    setErrors(errors[0]);
    setHelperText(errors[1]);
    setIsReady(errors[2]);
  };

  return (
    <div className={classes.root}>
      <TextField
        helperText={helperText.name}
        error={errors.name}
        onChange={(e) => handleChange(e, 'name')}
        required
        type={'text'}
        label={text[props.lang].name}
        variant='outlined'
      />
      <TextField
        helperText={helperText.email}
        error={errors.email}
        onChange={(e) => handleChange(e, 'email')}
        required
        type={'text'}
        label={text[props.lang].email}
        variant='outlined'
      />
      <TextField
        helperText={helperText.subject}
        error={errors.subject}
        onChange={(e) => handleChange(e, 'subject')}
        required
        type={'text'}
        label={text[props.lang].subject}
        variant='outlined'
      />
      <TextField
        helperText={helperText.message}
        error={errors.message}
        onChange={(e) => handleChange(e, 'message')}
        required
        type={'text'}
        multiline
        rows={4}
        label={text[props.lang].message}
        variant='outlined'
      />
      <button
        style={{
          opacity: isReady ? 1 : 0.5,
          pointerEvents: isReady ? 'all' : 'none',
          cursor: isReady ? 'pointer' : 'unset',
          width: '100%',
          maxWidth: 500,
          height: props.isMobile ? '50px' : '60px',
          fontSize: props.isMobile ? '20px' : '30px',
          borderRadius: 10,
        }}
        className={`white-hover center`}
        onClick={(e) => handleUpload()}
      >
        {text[props.lang].submit}
      </button>
    </div>
  );
};
