import React from 'react';


const divStyle={textAlign:'center',justifySelf:'center',width:'100%',cursor:'pointer',borderRadius:5,alignSelf:'center'}


export const AddRemoveItem=props=>{
    return(
        <div>
            <div style={{display:'grid',gridTemplateColumns:'1fr 1fr 1fr',gap:2,backgroundColor:'rgb(53, 55, 56)',boxShadow:'0px 0px 4px 0px rgba(0,0,0,0.1)',padding: 2,width:'70%',borderRadius:5,fontWeight:600}}>
                    <div className={'pink-hover'} style={divStyle} onClick={e=>props.reduceItem(props.id)}>&minus;</div>
                    <div  style={{...divStyle,cursor:'unset',backgroundColor:'white'}}>{props.quantity}</div>
                    <div  className={'pink-hover'} style={divStyle} onClick={e=>props.augmentItem(props.id)} >&#43;</div>
            </div>
        </div>
    );
}


