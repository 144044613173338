import React, { useEffect, useState } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Checkbox from '@material-ui/core/Checkbox';
import { Button, Slider, Typography } from '@material-ui/core';
import text from '../utils/text';

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    padding: '50px 0',
    minWidth: 300,
  },
  checksContainer: {
    display: 'flex',
    flexDirection: 'row',
  },
  formControl: {
    margin: theme.spacing(3),
  },
  slider: {
    width: '90%',
    margin: '0 auto',
  },
  formLabel: {
    minHeight: 50,
  },
}));

function valuetext(value) {
  return `${value}$`;
}

export default function Filter({
  categories = [],
  categoriesTranslater = {},
  onChange = () => {},
  lang,
  initial = {},
}) {
  const classes = useStyles();
  const [categoriesState, setCategoriesState] = useState({});
  const [range, setRange] = useState([0, 300]);

  useEffect(() => {
    setCategoriesState(initial.categories);
    setRange(initial.range);
  }, []);

  const handleCategoriesChange = (event) => {
    setCategoriesState((s) => ({
      ...s,
      [event.target.name]: event.target.checked,
    }));
  };

  const handlePriceChange = (e, value) => {
    setRange(value);
  };

  const onSave = () => {
    let header = {
      categories: [],
    };
    Object.keys(categoriesState).forEach((cat) => {
      if (categoriesState[cat]) {
        return header.categories.push(cat);
      }
    });
    header.range = `$${range[0]}-$${range[1]}+`;
    header.categories = header.categories
      .map((cat) => categoriesTranslater[cat][lang])
      .toString();
    onChange({
      header,
      categories: categoriesState,
      range,
    });
  };

  return (
    <div className={classes.root}>
      <div className={classes.slider}>
        <Typography id='price-range-slider' gutterBottom>
          {text.filter.priceRange[lang]} 0 - 300+ (USD)
        </Typography>
        <Slider
          min={0}
          max={300}
          step={5}
          value={range}
          onChange={handlePriceChange}
          valueLabelDisplay='auto'
          aria-labelledby='range-slider'
          getAriaValueText={valuetext}
        />
      </div>
      <div className={classes.checksContainer}>
        <FormControl component='fieldset' className={classes.formControl}>
          <FormLabel className={classes.formLabel} component='legend'>
            {text.filter.categories[lang]}
          </FormLabel>
          <FormGroup>
            {categories.map((cat) => {
              return (
                <FormControlLabel
                  key={cat.label}
                  style={{ textTransform: 'capitalize' }}
                  control={
                    <Checkbox
                      checked={categoriesState[cat.label] || false}
                      color='secondary'
                      onChange={handleCategoriesChange}
                      name={cat.label}
                    />
                  }
                  label={categoriesTranslater[cat.label][lang]}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </div>

      <Button
        onClick={onSave}
        className={classes.button}
        variant='contained'
        color='primary'
      >
        Filtrar
      </Button>
    </div>
  );
}
