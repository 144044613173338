import React from 'react';
import { emphasize, withStyles } from '@material-ui/core/styles';
import Breadcrumbs from '@material-ui/core/Breadcrumbs';
import Chip from '@material-ui/core/Chip';
import HomeIcon from '@material-ui/icons/Home';
import ShopIcon from '@material-ui/icons/Store';
import text from '../utils/text';
import { NavLink } from './Navbar';

const StyledBreadcrumb = withStyles((theme) => ({
  root: {
    backgroundColor: theme.palette.grey[100],
    height: theme.spacing(3),
    color: theme.palette.grey[800],
    fontWeight: theme.typography.fontWeightRegular,
    '&:hover, &:focus': {
      backgroundColor: theme.palette.grey[300],
    },
    '&:active': {
      boxShadow: theme.shadows[1],
      backgroundColor: emphasize(theme.palette.grey[300], 0.12),
    },
  },
}))(Chip); // TypeScript only: need a type cast here because https://github.com/Microsoft/TypeScript/issues/26591

function handleClick(event) {
  //   event.preventDefault();
  //   console.log(event);
}

export default function CustomizedBreadcrumbs(props) {
  return (
    <Breadcrumbs aria-label='breadcrumb'>
      <NavLink to='/'>
        <StyledBreadcrumb
          component='div'
          label={text.navbar[props.lang].home.toLowerCase()}
          icon={<HomeIcon fontSize='small' />}
          onClick={handleClick}
        />
      </NavLink>
      {props.shop ? (
        <NavLink to='/shop'>
          <StyledBreadcrumb
            icon={<ShopIcon fontSize='small' />}
            component='div'
            label={text.navbar[props.lang].shop.toLowerCase()}
            onClick={handleClick}
          />
        </NavLink>
      ) : null}
      {props.single ? (
        <StyledBreadcrumb
          component='div'
          label={props.name}
          deleteIcon={null}
        />
      ) : null}
    </Breadcrumbs>
  );
}
