import React from 'react';
import { useState } from 'react';
import LazyImage from './MyImage';
import { NavLink } from './Navbar';

export const ShopItem = ({
  id,
  addToCart,
  url,
  name,
  lang,
  prices,
  currency,
  isFeatured,
}) => {
  const [clicked, setClicked] = useState(false);
  const [isHovered, setIsHovered] = useState(false);

  const handleClick = (e) => {
    setClicked(true);
    setTimeout(() => {
      setClicked(false);
    }, 1000);
    addToCart(id);
  };

  return (
    <div
      onMouseEnter={(e) => setIsHovered(true)}
      onMouseLeave={(e) => setIsHovered(false)}
      className='shop_item column center is-featured'
      style={{ margin: isFeatured ? '0 auto' : '20px' }}
    >
      <NavLink to={`/shop/product/${id}`}>
        <LazyImage
          image={{
            src: url,
            alt: id,
            width: '100%',
            objectFit: 'cover',
          }}
        />
      </NavLink>
      {!isFeatured && (
        <>
          <div
            className={`item_action_container ${isHovered ? 'hovered' : null}`}
          >
            <div
              onClick={(e) => handleClick(e)}
              className={`add_item_button center column `}
            >
              <div className={`add_item_image ${clicked ? 'clicked' : null}`} />
            </div>
          </div>
          <p>
            {name[lang]}
            <br />
            {prices[currency]}
          </p>
        </>
      )}
    </div>
  );
};
