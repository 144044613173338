import React, { useState } from 'react';
import './Home.css';
import Slider from 'react-slick';

import LazyImage from './MyImage';
import mobile1 from '../images/home-min/mobile1.jpg';
import mobile2 from '../images/home-min/mobile2.jpg';
import mobile3 from '../images/home-min/mobile3.jpg';
import desktop1 from '../images/home-min/desktop1.jpg';
import desktop2 from '../images/home-min/desktop2.jpg';
import desktop3 from '../images/home-min/desktop3.jpg';
import middle from '../images/home-min/middle.jpg';
import bottom from '../images/home-min/bottom.jpg';

import { useEffect } from 'react';
import { useRef } from 'react';
// import HomeShopImage from './HomeShopImage';
import text from '../utils/text';
import { ShopItem } from './ShopItem';
import { Link } from '@reach/router';
import { Button } from '@material-ui/core';

var settings = {
  dots: true,
  arrows: false,
  // fade: true,
  autoplay: true,
};

const generateRandom = (num, max) => {
  var randomFiveIds = [];
  while (randomFiveIds.length < num) {
    var r = Math.floor(Math.random() * max) + 1;
    if (randomFiveIds.indexOf(r) === -1) randomFiveIds.push(r);
  }
  return randomFiveIds;
};

const desktopImages = [desktop3, desktop1, desktop2];

const mobileImages = [mobile1, mobile2, mobile3];

const images = window.innerWidth < 768 ? mobileImages : desktopImages;

export const Home = (props) => {
  const [randomProducts, setRandomProducts] = useState([]);

  const scrolled = useRef();
  const videoRef = useRef({ readyState: null });
  const handleScroll = (e) => {
    scrolled.current = window.scrollY > window.innerHeight / 3;
    // document.getElementById('navbar').scrollTop
    if (scrolled.current) {
      document.getElementById('navbar').classList.remove('nav-unscrolled');
    } else {
      document.getElementById('navbar').classList.add('nav-unscrolled');
    }
  };

  useEffect(() => {
    const randomFiveNums = generateRandom(
      3,
      Object.keys(props.products).length
    );
    const ids = Object.keys(props.products);
    const rProducts = randomFiveNums.map((index) => {
      if (props.products[ids[index]]?.urls) {
        return { ...props.products[ids[index]], id: ids[index] };
      } else {
        return { urls: [undefined] };
      }
    });
    setRandomProducts(rProducts);
  }, []);

  useEffect(() => {
    document.getElementById('navbar').classList.add('nav-unscrolled');
    document.addEventListener('scroll', handleScroll);
    return () => {
      document.removeEventListener('scroll', handleScroll);
      document.getElementById('navbar').classList.remove('nav-unscrolled');
    };
  }, []);

  return (
    <div className='home'>
      <div className='container'>
        <Slider {...settings}>
          {images.map((img) => (
            <div>
              <LazyImage
                blur
                className='section3-image'
                image={{ src: img, alt: 'home1', placeHolder: img }}
              />
            </div>
          ))}
        </Slider>
      </div>
      <div>
        {window.innerWidth < 768 ? (
          <Slider>
            {randomProducts.map((product) => {
              return (
                <div>
                  <ShopItem url={product.urls[0]} id={product.id} isFeatured />
                </div>
              );
            })}
          </Slider>
        ) : (
          <div
            style={{
              display: 'flex',
              flexWrap: 'wrap',
              justifyContent: 'center',
              alignItems: 'center',
              width: '90%',
              margin: '40px auto',
            }}
          >
            {randomProducts.map((product) => {
              if (!product?.urls?.[0]) return null;
              return (
                <div className='home-shop-item'>
                  <ShopItem
                    url={product?.urls?.[0]}
                    id={product.id}
                    isFeatured
                  />
                </div>
              );
            })}
          </div>
        )}
        <Link to='/shop'>
          <h3 className='home-shop-button'>
            {text.home[props.lang].shopButton}
          </h3>
        </Link>
      </div>

      <h2
        style={{
          width: '80%',
          maxWidth: 800,
          margin: '50px auto',
          textAlign: 'center',
        }}
      >
        {text.home[props.lang].middle}
      </h2>

      <Link to='/story'>
        <div className='readmore_container'>
          <Button size='large' variant='contained' color='primary'>
            Read more
          </Button>
        </div>
      </Link>

      <div className='home-default-images-wrapper'>
        <div className='home-default-image-wrapper'>
          <LazyImage
            blur
            className='home-default-image'
            image={{ src: middle, alt: 'Maria', placeHolder: middle }}
          />
        </div>

        <div className='home-default-image-wrapper'>
          <LazyImage
            blur
            className='home-default-image'
            image={{ src: bottom, alt: 'Custom art', placeHolder: bottom }}
          />
        </div>
      </div>
    </div>
  );
};
