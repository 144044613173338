import React, { useState } from 'react';
import { Form } from './Form';
import text from '../utils/text';
import './Contact.css';

const Contact = (props) => {
  const [sent, setSent] = useState(false);

  return (
    <div className='main contact_bg'>
      {!sent ? (
        <div
          style={{
            backgroundColor: 'rgba(255, 255, 255, 0.75)',
            borderRadius: '14px',
          }}
        >
          <h1 style={{ margin: '5vh 0', textAlign: 'center' }}>
            {text.contact.heading[props.lang]}
          </h1>
          <Form lang={props.lang} isSent={setSent} isMobile={props.isMobile} />
        </div>
      ) : (
        <div>
          <h1 style={{ margin: '5vh 0', textAlign: 'center' }}>
            {text.contact.message[props.lang]}
          </h1>
        </div>
      )}
    </div>
  );
};

export default Contact;
