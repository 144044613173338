import React from 'react'



const Loader=()=>(
    <div style={{display:'flex',width:'100%',height:'100%',justifyContent:'center',alignItems:'center'}}>
        <div className="lds-spinner"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
    </div>
)

export default Loader