/* eslint-disable react/display-name */
import { Button, IconButton, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "./components/DataTable";
import AddIcon from "@material-ui/icons/Add";
import DeleteIcon from "@material-ui/icons/Delete";
import { ModalContext } from "./components/SimpleModal";

import { addCoupon, deleteCoupon, getCoupons } from "../utils/dbRequests";
import AddCoupon from "./AddCoupon";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexGrow: 1,

		flexDirection: "column",
		alignContent: "center",
		justifyContent: "flex-start",
	},
	button: {
		maxWidth: 200,
	},
}));

export default function Coupons({ db, storage, changeRates }) {
	const classes = useStyles();
	const handleModal = useContext(ModalContext);
	const [coupons, setCoupons] = useState([]);

	const init = async () => {
		refresh();
	};

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const columns = [
		{
			field: "code",
			headerName: "Code",
			width: 250,
		},
		{
			field: "amount",
			headerName: "Coupon Amount (Percent)",
			width: 250,
			renderCell: (params) => <>{params.value}%</>,
		},
		{
			field: "id",
			headerName: "Delete",
			sortable: false,
			width: 100,
			renderCell: (params) => (
				<IconButton
					variant="contained"
					color="primary"
					size="small"
					onClick={() => onDelete(params.value)}
					style={{ marginLeft: 16 }}
				>
					<DeleteIcon />
				</IconButton>
			),
		},
	];

	const refresh = async () => {
		setCoupons(await getCoupons(db));
	};

	const onSave = async (info) => {
		if (coupons.findIndex((val) => val.code === info.code) !== -1) {
			alert("Change the coupon code! It has to be unique");
			return;
		}
		let coupon = {
			...info,
			id: Math.round(Math.random() * 100000),
		};
		await addCoupon(db, coupon);
		handleModal();
		refresh();
	};

	const onDelete = async (id) => {
		await deleteCoupon(db, id);
		refresh();
	};

	const openAdd = (product) => {
		handleModal(<AddCoupon onSave={onSave} onCancel={() => handleModal()} />);
	};

	return (
		<div>
			<Typography variant="h3">Coupons</Typography>

			<div className={classes.root}>
				<Button
					onClick={() => openAdd()}
					className={classes.button}
					startIcon={<AddIcon />}
					variant="outlined"
				>
					Add Coupon
				</Button>
				<DataTable rows={coupons} columns={columns} />
			</div>
		</div>
	);
}
