/* eslint-disable react/display-name */
import { Button, makeStyles, Typography } from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "./components/DataTable";
import { ModalContext } from "./components/SimpleModal";
import { getOrders } from "../utils/dbRequests";
import DetailsComponent from "./components/DetailsComponent";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexGrow: 1,
		minHeight: "70vh",
		flexDirection: "column",
		alignContent: "center",
		justifyContent: "flex-start",
	},
	button: {
		maxWidth: 200,
	},
}));

export default function Orders({ db, storage }) {
	const classes = useStyles();
	const handleModal = useContext(ModalContext);
	const [orders, setOrders] = useState([]);

	useEffect(() => {
		refresh();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const openClientInfo = (clientInfo, shippingInfo, paypalShipping) => {
		handleModal(
			<div>
				<h2 style={{ marginTop: 30 }}>General client info</h2>
				<DetailsComponent data={clientInfo} />
				<h2 style={{ marginTop: 30 }}>Client shipping address</h2>
				<DetailsComponent data={shippingInfo} />
				{paypalShipping && (
					<>
						<h2 style={{ marginTop: 30 }}>Paypal shipping address</h2>
						<DetailsComponent data={paypalShipping} />
					</>
				)}
			</div>
		);
	};

	const openItemsInfo = (items) => {
		const processed = {};
		items.forEach((item) => {
			processed[`${item.title} (${item.quantity})`] = `$${
				item.unit_price * item.quantity
			}`;
		});
		handleModal(
			<div>
				<h2 style={{ marginTop: 30 }}>Items</h2>
				<DetailsComponent data={processed} />
			</div>
		);
	};

	const columns = [
		{
			field: "id",
			headerName: "Order ID",
			width: 250,
		},
		{
			field: "date",
			headerName: "Date",
			width: 250,
			renderCell: (params) =>
				params.value && new Date(params.value).toLocaleString(),
		},
		{
			field: "client",
			headerName: "Client Info",
			width: 200,
			renderCell: (params) => (
				<Button
					variant="contained"
					color="primary"
					onClick={() =>
						openClientInfo(
							params.value,
							params.row.shippingAddress,
							params.row.shippingAddressPaypal?.address
						)
					}
				>
					See client Info
				</Button>
			),
		},
		{
			field: "type",
			headerName: "Payment service",
			type: "string",
			width: 180,
		},
		{
			field: "items",
			headerName: "Items",
			width: 200,
			renderCell: (params) => (
				<Button
					variant="contained"
					color="primary"
					onClick={() => openItemsInfo(params.value)}
				>
					{`Items (${params.value.reduce((acc, current) => {
						return acc + current.quantity;
					}, 0)}) - $${params.row.total - params.row.shipping}`}
				</Button>
			),
		},
		{
			field: "shipping",
			headerName: "Shipping",
			width: 100,
			renderCell: (params) => `$${params.value}`,
		},

		{
			field: "total",
			headerName: "Total",
			type: "string",
			width: 100,
			renderCell: (params) => <>{params.value}$</>,
		},
	];

	const refresh = async () => {
		let orders = await getOrders(db);
		setOrders(orders);
	};

	return (
		<div>
			<Typography variant="h3">Products</Typography>

			<div className={classes.root}>
				{/* <Button
            onClick={() => openAdd()}
            className={classes.button}
            startIcon={<AddIcon />}
            variant='outlined'
          >
            Add Product
          </Button> */}
				<DataTable rows={orders} columns={columns} />
			</div>
		</div>
	);
}
