import * as React from "react";
import { DataGrid } from "@material-ui/data-grid";
import { makeStyles } from "@material-ui/core";

const defaultColumns = [
	{ field: "id", headerName: "ID", width: 70 },
	{ field: "firstName", headerName: "First name", width: 130 },
	{ field: "lastName", headerName: "Last name", width: 130 },
	{
		field: "age",
		headerName: "Age",
		type: "number",
		width: 90,
	},
	{
		field: "fullName",
		headerName: "Full name",
		description: "This column has a value getter and is not sortable.",
		sortable: false,
		width: 160,
		valueGetter: (params) =>
			`${params.getValue("firstName") || ""} ${
				params.getValue("lastName") || ""
			}`,
	},
];

const useStyles = makeStyles((theme) => ({
	root: {
		"& .MuiDataGrid-colCell, .MuiDataGrid-cell": {
			borderRight: `1px solid ${
				theme.palette.type === "light" ? "#f0f0f0" : "#303030"
			}`,
		},
		"& .MuiDataGrid-columnsContainer, .MuiDataGrid-cell": {
			borderBottom: `1px solid ${
				theme.palette.type === "light" ? "#f0f0f0" : "#303030"
			}`,
		},
		"& .MuiDataGrid-cell": {
			color:
				theme.palette.type === "light"
					? "rgba(0,0,0,.85)"
					: "rgba(255,255,255,0.65)",
		},
	},
}));

export default function DataTable({ columns = defaultColumns, rows }) {
	const classes = useStyles();
	return (
		<div style={{ height: 500, width: "100%" }}>
			<DataGrid
				className={classes.root}
				rows={rows}
				columns={columns}
				pageSize={10}
			/>
		</div>
	);
}
