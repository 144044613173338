import { createMuiTheme, ThemeProvider } from "@material-ui/core";
import { Router, useLocation } from "@reach/router";
import React, { useEffect, useReducer, useState } from "react";
import SimpleModal from "./Admin/components/SimpleModal";
import { About } from "./components/About";
import { CartPage } from "./components/CartPage";
import Contact from "./components/Contact";
import Custom from "./components/Custom";
import Footer from "./components/Footer";
import { Home } from "./components/Home";
import { ItemSingle } from "./components/ItemSingle";
import { Navbar } from "./components/Navbar";
import NoStockDialog from "./components/NoStockDialog";
import OrderStatus from "./components/OrderStatus";
import { Shop } from "./components/Shop";
import Terms from "./components/Terms";
import text from "./utils/text";
import { getTotal, scrollToTop, tooltipAdded } from "./utils/utils";

const initialState = { items: {}, amount: 0, total: 0, shipping: 0 };

const theme = createMuiTheme({
	palette: {
		primary: {
			main: "#333",
		},
	},
	typography: {
		button: {
			fontWeight: 600,
			// textTransform: 'capitalize',
		},
	},
});

function reducer(cart, action) {
	let newCart = cart;
	let newAmount = newCart.amount;
	let total = 0;
	switch (action.type) {
		case "set_shipping":
			return {
				...cart,
				total: getTotal(newCart.items, cart.coupon, action.shipping),
				shipping: action.shipping,
			};
		case "apply_coupon":
			return {
				...cart,
				total: getTotal(newCart.items, action.coupon),
				coupon: action.coupon,
			};
		case "reset_cart":
			return { ...initialState };
		case "init_cart":
			if (action.products[action.pair[0]]) {
				newCart.items[action.pair[0]] = action.products[action.pair[0]];
				newCart.items[action.pair[0]].amount = action.pair[1];
				newAmount += newCart.items[action.pair[0]].amount;
				total = getTotal(newCart.items, cart.coupon);
				return { ...cart, items: newCart.items, amount: newAmount, total };
			}
			return cart;
		case "add_item":
			if (newCart.items[action.id]) {
				if (action.stock[action.id].stock > newCart.items[action.id].amount) {
					newCart.items[action.id].amount++;
					newAmount++;
					tooltipAdded();
				} else {
					action.openAlert();
				}
				total = getTotal(newCart.items, cart.coupon);
			} else {
				if (Number(action.stock[action.id].stock) !== 0) {
					newCart.items[action.id] = action.products[action.id];
					newCart.items[action.id].amount = 1;
					newAmount++;
					tooltipAdded();
					total = getTotal(newCart.items, cart.coupon);
				} else {
					action.openAlert();
				}
			}
			return { ...cart, items: newCart.items, amount: newAmount, total };
		case "delete_item":
			let amountRemoved = 0;
			if (newCart.items[action.id]) {
				amountRemoved = newCart.items[action.id].amount;
				delete newCart.items[action.id];
			}
			total = getTotal(newCart.items, cart.coupon);
			return {
				...cart,
				items: newCart.items,
				amount: cart.amount - amountRemoved,
				total,
			};
		case "reduce_item":
			if (newCart.items[action.id]) {
				newCart.items[action.id].amount--;
				if (newCart.items[action.id].amount === 0) {
					delete newCart.items[action.id];
				}
			}
			total = getTotal(newCart.items, cart.coupon);
			return {
				...cart,
				items: newCart.items,
				amount: newCart.amount - 1,
				total,
			};
		case "augment_item":
			if (newCart.items[action.id]) {
				if (action.stock[action.id].stock > newCart.items[action.id].amount) {
					newCart.items[action.id].amount++;
					newAmount++;
				} else {
					action.openAlert();
				}
			}
			total = getTotal(newCart.items, cart.coupon);
			return { ...cart, items: newCart.items, amount: newAmount, total };
		default:
			throw new Error();
	}
}
export default function MainView({
	stock,
	products,
	changeRates,
	productCategories,
	shippingPrices,
	db,
}) {
	const [lang, setLang] = useState("EN");
	const [isMobile, setIsMobile] = useState(window.innerWidth < 1024);
	const [cart, updateCart] = useReducer(reducer, initialState);
	const [retrievedCart, setRetreivedCart] = useState(false);
	const location = useLocation();
	const [currency, setCurrency] = useState({
		value: "USD",
		label: isMobile ? "$" : "USD",
	});
	const [stockAlert, setStockAlert] = useState(false);

	const selectCurrency = (selectedOption) => {
		setCurrency(selectedOption);
	};

	const changeLang = (e) => {
		if (e.target.checked) {
			setLang("ES");
		} else {
			setLang("EN");
		}
	};

	useEffect(() => {
		if (retrievedCart) {
			let items = [];
			Object.keys(cart.items).forEach((item) => {
				items.push(`${item}:${cart.items[item].amount}`);
			});
			localStorage.setItem("cart", items);
		}
	}, [cart, retrievedCart]);

	useEffect(() => {
		if (location.pathname !== "/payment-status") {
			let items = localStorage.getItem("cart");
			if (items !== null) {
				items = items.split(",");
				items.forEach((item) => {
					if (item !== "") {
						let pair = item.split(":");
						pair[1] = Number(pair[1]);
						initializeCart(pair);
					}
				});
			}
		}

		setRetreivedCart(true);
		scrollToTop();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const handleResize = () => {
		setIsMobile(window.innerWidth < 1024);
	};

	useEffect(() => {
		window.addEventListener("resize", handleResize);
		return () => {
			window.removeEventListener("resize", handleResize);
		};
		// eslint-disable-next-line
	}, []);

	const openAlert = () => {
		setStockAlert(true);
	};

	const addToCart = (id) => {
		updateCart({ type: "add_item", id, products, stock, openAlert });
	};

	const setShipping = (amount) => {
		updateCart({ type: "set_shipping", shipping: amount });
	};

	const resetCart = () => {
		updateCart({ type: "reset_cart" });
	};
	const applyCoupon = (coupon) => {
		updateCart({ type: "apply_coupon", coupon });
	};

	const removeFromCart = (id) => {
		updateCart({ type: "delete_item", id, products, stock });
	};

	const reduceItem = (id) => {
		updateCart({ type: "reduce_item", id, products, stock });
	};

	const augmentItem = (id) => {
		updateCart({ type: "augment_item", id, products, stock, openAlert });
	};

	const initializeCart = (pair) => {
		updateCart({ type: "init_cart", pair, products });
	};

	return (
		<>
			<ThemeProvider theme={theme}>
				<SimpleModal>
					<Navbar
						selectCurrency={selectCurrency}
						changeInfo={{ currency: currency, changeRates }}
						currency={currency}
						isMobile={isMobile}
						cart={cart}
						augmentItem={augmentItem}
						reduceItem={reduceItem}
						removeFromCart={removeFromCart}
						lang={lang}
						changeLang={changeLang}
					/>
					<Router>
						<Home
							path="/home"
							products={products}
							isMobile={isMobile}
							lang={lang}
						/>
						<Shop
							path="/shop"
							isMobile={isMobile}
							currency={currency.value}
							categories={productCategories}
							addToCart={addToCart}
							products={products}
							lang={lang}
							stock={stock}
						/>
						<Shop
							path="/shop/:filterParam"
							isMobile={isMobile}
							categories={productCategories}
							currency={currency.value}
							addToCart={addToCart}
							products={products}
							lang={lang}
							stock={stock}
						/>
						<About path="/story" lang={lang} />
						<ItemSingle
							path="/shop/product/:id"
							currency={currency.value}
							addToCart={addToCart}
							products={products}
							lang={lang}
						/>
						<CartPage
							path="/cart"
							db={db}
							lang={lang}
							cart={cart}
							currency={currency}
							shippingPrices={shippingPrices}
							applyCoupon={applyCoupon}
							changeInfo={{ currency: currency.value, changeRates }}
							augmentItem={augmentItem}
							reduceItem={reduceItem}
							removeFromCart={removeFromCart}
							setShipping={setShipping}
							isMobile={isMobile}
						/>
						<Custom path="/shop/custom" isMobile={isMobile} lang={lang} />
						<Contact path="/contact" isMobile={isMobile} lang={lang} />
						<Terms
							path="/terms-and-conditions"
							lang={lang}
							type="terms"
							name={text.footer[lang].terms}
						/>
						<Terms
							path="/shipping-policy"
							lang={lang}
							type="shippingPolicy"
							name={text.footer[lang].shipping}
						/>
						<OrderStatus
							resetCart={resetCart}
							lang={lang}
							path="/payment-status"
						/>
					</Router>
					<NoStockDialog
						lang={lang}
						open={stockAlert}
						setOpen={setStockAlert}
					/>
					<Footer lang={lang} />
				</SimpleModal>
			</ThemeProvider>
		</>
	);
}
