import React, { useEffect, useState } from 'react';
import { changeCurrency } from '../utils/utils';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten, makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import TableSortLabel from '@material-ui/core/TableSortLabel';
import Toolbar from '@material-ui/core/Toolbar';
import Typography from '@material-ui/core/Typography';
import Paper from '@material-ui/core/Paper';
import Checkbox from '@material-ui/core/Checkbox';
import IconButton from '@material-ui/core/IconButton';
import Tooltip from '@material-ui/core/Tooltip';
import DeleteIcon from '@material-ui/icons/Delete';
import { AddRemoveItem } from './AddRemoveItem';
import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core';
import { getCoupons } from '../utils/dbRequests';
import { db } from '../App';

const isMobile = window.innerWidth < 768;

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}

let headCells = [];

function EnhancedTableHead(props) {
  const {
    classes,
    onSelectAllClick,
    order,
    orderBy,
    numSelected,
    rowCount,
    onRequestSort,
  } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  useEffect(() => {
    headCells = [
      {
        id: 'name',
        numeric: false,
        disablePadding: true,
        label: props.text.checkout[props.lang].name,
      },
      {
        id: 'price',
        numeric: true,
        disablePadding: false,
        label: props.text.checkout[props.lang].price,
      },
      {
        id: 'quantity',
        numeric: false,
        disablePadding: false,
        label: props.text.checkout[props.lang].quantity,
      },
      { id: 'total', numeric: true, disablePadding: false, label: 'Subtotal' },
    ];
  }, [props.lang, props.text.checkout]);

  return (
    <TableHead>
      <TableRow>
        <TableCell padding='checkbox'>
          <Checkbox
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{ 'aria-label': 'select all desserts' }}
          />
        </TableCell>
        {headCells.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'default'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.type === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
}));

const EnhancedTableToolbar = (props) => {
  const classes = useToolbarStyles();
  const { numSelected } = props;

  return (
    <Toolbar
      className={clsx(classes.root, {
        [classes.highlight]: numSelected > 0,
      })}
    >
      {numSelected > 0 ? (
        <Typography
          className={classes.title}
          color='inherit'
          variant='subtitle1'
          component='div'
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          className={classes.title}
          variant='h6'
          id='tableTitle'
          component='div'
        >
          Cart
        </Typography>
      )}

      {numSelected > 0 ? (
        <Tooltip title='Delete'>
          <IconButton onClick={(e) => props.handleDelete()} aria-label='delete'>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : null}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

const useStyles = makeStyles((theme) => ({
  root: {
    width: '100vw',
    maxWidth: 800,
    margin: '50px 0',
    fontFamily: 'Sorts Mill Goudy',
    // margin: window.innerWidth < 769 ? '8vmin 0' : '2vmin 0',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 200,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  total: {
    padding: '16px',
  },
  shipping: {
    width: 200,
    [theme.breakpoints.down('sm')]: {
      width: '100%',
    },
  },
  coupon: {
    width: '100%',
  },
}));

export default function EnhancedTable(props) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('price');
  const [selected, setSelected] = React.useState([]);
  // eslint-disable-next-line
  const [page, setPage] = React.useState(0);
  // eslint-disable-next-line
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [items, setItems] = useState([]);
  const [coupons, setCoupons] = useState();
  const [couponCode, setCouponCode] = useState('');

  const checkCoupon = () => {
    let coup = coupons.find((coupon) => coupon.code === couponCode);
    props.applyCoupon(coup);
  };

  const init = async () => {
    setCoupons(await getCoupons(db));
  };

  useEffect(() => {
    init();
  }, []);

  useEffect(() => {
    let newRows = Object.keys(props.cart.items).map((id) => {
      let item = props.cart.items[id];
      return {
        name: item.name[props.lang],
        prices: item.prices,
        quantity: item.amount,
        total: item.price * item.amount,
        id,
      };
    });
    setItems(newRows);
  }, [props.cart, props.lang]);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = items.map((n) => n.id);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };

  const handleClick = (event, name) => {
    const selectedIndex = selected.indexOf(name);
    let newSelected = [];

    if (selectedIndex === -1) {
      newSelected = newSelected.concat(selected, name);
    } else if (selectedIndex === 0) {
      newSelected = newSelected.concat(selected.slice(1));
    } else if (selectedIndex === selected.length - 1) {
      newSelected = newSelected.concat(selected.slice(0, -1));
    } else if (selectedIndex > 0) {
      newSelected = newSelected.concat(
        selected.slice(0, selectedIndex),
        selected.slice(selectedIndex + 1)
      );
    }

    setSelected(newSelected);
  };

  const handleDelete = () => {
    selected.forEach((id) => props.removeFromCart(id));
    setSelected([]);
  };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  return (
    <div className={classes.root}>
      <Paper className={classes.paper}>
        <EnhancedTableToolbar
          selected={selected}
          handleDelete={handleDelete}
          numSelected={selected.length}
        />
        <TableContainer>
          <Table
            className={classes.table}
            aria-labelledby='tableTitle'
            size={'medium'}
            aria-label='enhanced table'
          >
            <EnhancedTableHead
              lang={props.lang}
              text={props.text}
              classes={classes}
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={items.length}
            />
            <TableBody>
              {stableSort(items, getComparator(order, orderBy))
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                  const isItemSelected = isSelected(row.id);
                  const labelId = `enhanced-table-checkbox-${index}`;

                  return (
                    <TableRow
                      hover
                      role='checkbox'
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={row.id}
                      selected={isItemSelected}
                    >
                      <TableCell padding='checkbox'>
                        <Checkbox
                          onClick={(event) => handleClick(event, row.id)}
                          checked={isItemSelected}
                          inputProps={{ 'aria-labelledby': labelId }}
                        />
                      </TableCell>
                      <TableCell
                        component='th'
                        id={labelId}
                        scope='row'
                        colSpan={1}
                        padding='none'
                      >
                        {row.name}
                      </TableCell>
                      <TableCell align='right'>
                        {row.prices[props.changeInfo.currency]}
                      </TableCell>
                      <TableCell align='right'>
                        <AddRemoveItem
                          reduceItem={props.reduceItem}
                          augmentItem={props.augmentItem}
                          id={row.id}
                          quantity={row.quantity}
                        />
                      </TableCell>
                      <TableCell align='right'>
                        {changeCurrency(
                          row.total,
                          props.changeInfo.currency,
                          props.changeInfo.changeRates
                        )}
                      </TableCell>
                    </TableRow>
                  );
                })}
              <TableRow>
                {!isMobile && <TableCell rowSpan={3} colSpan={2} />}

                <TableCell>
                  {props.text.checkout[props.lang].shipping.shipping}
                </TableCell>
                <TableCell colSpan={isMobile ? 3 : 0} align='left'>
                  {
                    <FormControl
                      variant='outlined'
                      className={classes.shipping}
                    >
                      <Select
                        onChange={(e) =>
                          props.setShipping(Number(e.target.value))
                        }
                        defaultValue=''
                        placeholder='Select shipping type'
                        labelId='shipping'
                        id='shipping'
                        displayEmpty
                      >
                        <MenuItem disabled value=''>
                          <em>
                            {
                              props.text.checkout[props.lang].shipping
                                .placeholder
                            }
                          </em>
                        </MenuItem>
                        <MenuItem value={props.shippingPrices.lima}>
                          Lima
                        </MenuItem>
                        <MenuItem value={props.shippingPrices.national}>
                          {props.text.checkout[props.lang].shipping.national}
                        </MenuItem>
                        <MenuItem value={props.shippingPrices.international}>
                          {
                            props.text.checkout[props.lang].shipping
                              .international
                          }
                        </MenuItem>
                      </Select>
                    </FormControl>
                  }
                </TableCell>
                <TableCell align='right'>
                  {props.cart.shipping !== 0
                    ? changeCurrency(
                        props.cart.shipping,
                        props.changeInfo.currency,
                        props.changeInfo.changeRates
                      )
                    : props.text.checkout[props.lang].shipping.placeholder}
                </TableCell>
              </TableRow>
              <TableRow>
                <TableCell colSpan={6} align='right'>
                  {!props.cart.coupon ? (
                    <div className={classes.coupon}>
                      <TextField
                        onChange={(e) => setCouponCode(e.target.value)}
                        style={{ height: 30 }}
                        variant='outlined'
                        name='coupon'
                        id='coupon-input'
                        placeholder={'Coupon Code'}
                      />
                      <Button
                        onClick={checkCoupon}
                        style={{ height: 46, marginLeft: 5 }}
                        variant='contained'
                        color='primary'
                      >
                        Apply
                      </Button>
                    </div>
                  ) : (
                    <Typography variant='subtitle1' id='coupon' component='div'>
                      {props.cart.coupon.code} - {props.cart.coupon.amount}% off
                    </Typography>
                  )}
                </TableCell>
              </TableRow>
              <TableRow>
                {isMobile && <TableCell colSpan={3} />}
                <TableCell colSpan={isMobile ? 0 : 2}>Total</TableCell>
                <TableCell align='right'>
                  {changeCurrency(
                    props.cart.total,
                    props.changeInfo.currency,
                    props.changeInfo.changeRates
                  )}
                </TableCell>
              </TableRow>
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
    </div>
  );
}
