import React from 'react';

export default function DetailsComponent({ data }) {
  return (
    <div style={{ minWidth: 400, border: '1px solid lightgray' }}>
      {Object.keys(data).map((field) => {
        return (
          <div
            style={{
              display: 'flex',
              padding: 10,
              justifyContent: 'space-between',
              borderBottom: '1px solid lightgray',
            }}
          >
            <p
              style={{
                fontWeight: 600,
                textTransform: 'capitalize',
              }}
            >
              {field.replace('_', ' ')}
            </p>
            <p>{data[field]}</p>
          </div>
        );
      })}
    </div>
  );
}
