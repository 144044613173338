import React from 'react';
import text from '../utils/text';
import { useRef,useEffect } from 'react';



export default function Terms({lang,type,name}){
    const p = useRef(null);
    useEffect(()=>{
        p.current.innerHTML = text[type][lang].replace(/\n/g , "<br>");
    },[lang,type]);
    return(
        <div style={{padding:'10vh 0'}} className='main'>
            <h1>{name}</h1>
            <p ref={p} className='terms-p'>{text[type][lang]}</p>
        </div>
    )
}