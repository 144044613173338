import React from 'react';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import TextField from '@material-ui/core/TextField';
import text from '../utils/text';

const fields = [
  {
    name: 'name',
    label: 'First name',
    autoComplete: 'given-name',
  },
  {
    name: 'surname',
    label: 'Last name',
    autoComplete: 'family-name',
  },
  {
    name: 'email',
    label: 'Email',
    autoComplete: 'email',
    fullWidth: true,
  },
  {
    name: 'street_name',
    label: 'Street Name',
  },
  {
    name: 'street_number',
    label: 'Street Number',
    autoComplete: undefined,
    type: 'number',
  },
  {
    name: 'zip_code',
    label: 'Zip code',
    autoComplete: undefined,
  },
  {
    name: 'city_name',
    label: 'City',
  },
  {
    name: 'state_name',
    label: 'State/Province/Region',
  },
  {
    name: 'country_name',
    label: 'Country',
    autoComplete: 'shipping country',
  },
];

export default function AddressForm({
  shippingInfo,
  setShippingInfo,
  errors,
  helperText,
  lang,
}) {
  const handleChange = (e) => {
    setShippingInfo((s) => ({ ...s, [e.target.name]: e.target.value }));
  };

  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>
        {text.checkout[lang].header1}
      </Typography>
      <Grid container spacing={3}>
        {fields.map((field) => (
          <Grid item xs={12} sm={field.fullWidth ? 12 : 6}>
            <TextField
              required
              id={field.name}
              type={field.type}
              helperText={helperText[field.name]}
              error={errors[field.name]}
              name={field.name}
              onChange={handleChange}
              label={text.checkout[lang].form[field.name]}
              fullWidth
              defaultValue={shippingInfo[field.name]}
              autoComplete={field.autoComplete}
            />
          </Grid>
        ))}
      </Grid>
    </React.Fragment>
  );
}
