import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Paper from '@material-ui/core/Paper';
import Stepper from '@material-ui/core/Stepper';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import AddressForm from './AddressForm';
import PaymentForm from './PaymentForm';
import Review from './Review';
import text from '../utils/text';
import './Checkout.css';

const useStyles = makeStyles((theme) => ({
  appBar: {
    position: 'relative',
  },
  layout: {
    width: 'auto',
    marginLeft: theme.spacing(2),
    marginRight: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(2) * 2)]: {
      width: 600,
      marginLeft: 'auto',
      marginRight: 'auto',
    },
  },
  paper: {
    marginTop: theme.spacing(3),
    marginBottom: theme.spacing(3),
    padding: theme.spacing(2),
    [theme.breakpoints.up(600 + theme.spacing(3) * 2)]: {
      marginTop: theme.spacing(6),
      marginBottom: theme.spacing(6),
      padding: theme.spacing(3),
    },
  },
  stepper: {
    padding: theme.spacing(3, 0, 5),
  },
  buttons: {
    display: 'flex',
    justifyContent: 'flex-end',
  },
  button: {
    marginTop: theme.spacing(3),
    marginLeft: theme.spacing(1),
  },
}));

const steps = ['header1', 'header2'];

export default function Checkout({
  cart,
  currency,
  lang,
  onCancel,
  db,
  closeModal,
}) {
  const classes = useStyles();
  const [activeStep, setActiveStep] = React.useState(0);
  const [shippingInfo, setShippingInfo] = React.useState({});
  const [formErrores, setFormErrores] = React.useState({});
  const [formHelperText, setFormHelperText] = React.useState({});
  const [isReady, setIsReady] = React.useState(false);

  const handleNext = () => {
    let errors = validate(shippingInfo);
    setFormErrores(errors[0]);
    setFormHelperText(errors[1]);
    setIsReady(errors[2]);
    console.log(errors);
    if (errors[2]) {
      setActiveStep(activeStep + 1);
    }
  };

  const handleBack = () => {
    if (activeStep === 0) {
      onCancel();
    } else {
      setActiveStep(activeStep - 1);
    }
  };

  const validate = (values) => {
    const errors = {};
    let ready = true;
    const helperText = {};
    const requiredFields = [
      'name',
      'surname',
      'email',
      'street_name',
      'zip_code',
      'city_name',
      'state_name',
      'country_name',
    ];
    requiredFields.forEach((field) => {
      if (!values[field]) {
        ready = false;
        errors[field] = true;
        helperText[field] = text.checkout[lang].form.required;
      } else {
        errors[field] = false;
        helperText[field] = null;
      }
    });
    if (
      values.email &&
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = true;
      ready = false;
      helperText.email = text.checkout[lang].form.emailInvalid;
    }
    return [errors, helperText, ready];
  };

  function getStepContent(step) {
    switch (step) {
      case 0:
        return (
          <AddressForm
            helperText={formHelperText}
            lang={lang}
            errors={formErrores}
            shippingInfo={shippingInfo}
            setShippingInfo={setShippingInfo}
          />
        );
      case 1:
        return (
          <PaymentForm
            db={db}
            shippingInfo={shippingInfo}
            lang={lang}
            cart={cart}
            closeModal={closeModal}
            currency={currency}
          />
        );
      case 2:
        return <Review />;
      default:
        throw new Error('Unknown step');
    }
  }

  return (
    <React.Fragment>
      <CssBaseline />
      <main className={classes.layout}>
        <Paper className={classes.paper}>
          <Typography component='h1' variant='h4' align='center'>
            Checkout
          </Typography>
          <Stepper activeStep={activeStep} className={classes.stepper}>
            {steps.map((label) => (
              <Step key={label}>
                <StepLabel>{text.checkout[lang][label]}</StepLabel>
              </Step>
            ))}
          </Stepper>
          <React.Fragment>
            {activeStep === steps.length ? (
              <React.Fragment>
                <Typography variant='h5' gutterBottom>
                  Thank you for your order.
                </Typography>
                <Typography variant='subtitle1'>
                  Your order number is #2001539. We have emailed your order
                  confirmation, and will send you an update when your order has
                  shipped.
                </Typography>
              </React.Fragment>
            ) : (
              <React.Fragment>
                {getStepContent(activeStep, cart, currency)}
                <div className={classes.buttons}>
                  <Button onClick={handleBack} className={classes.button}>
                    {activeStep !== 0 ? 'Back' : 'Cancel'}
                  </Button>
                  {activeStep === 0 && (
                    <Button
                      variant='contained'
                      color='primary'
                      onClick={handleNext}
                      className={classes.button}
                    >
                      Next
                    </Button>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        </Paper>
      </main>
    </React.Fragment>
  );
}
