import React, { useContext } from 'react';
import EnhancedTable from './EnhancedTable';
import CustomizedBreadcrumbs from './CustomizedBreadCrumbs';
import text from '../utils/text';
import { NavLink } from './Navbar';
import './CartPage.css';
import { Button } from '@material-ui/core';
import { ModalContext } from '../Admin/components/SimpleModal';
import Checkout from './Checkout';

export const CartPage = (props) => {
  const handleModal = useContext(ModalContext);

  const onCheckout = () => {
    handleModal(
      <Checkout
        onCancel={() => handleModal()}
        lang={props.lang}
        closeModal={handleModal}
        db={props.db}
        cart={props.cart}
        currency={props.currency}
      />
    );
  };

  if (props.cart.amount === 0) {
    return (
      <div className='main' style={{ minHeight: '50%' }}>
        <div className='bread-crumbs-container'>
          <CustomizedBreadcrumbs
            lang={props.lang}
            shop
            single
            name='Checkout'
          />
        </div>
        <h1
          style={{
            textAlign: 'center',
            height: '80vh',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          {text.checkout[props.lang].empty1}&nbsp;
          <NavLink to='/shop'>{text.checkout[props.lang].empty2}</NavLink>
        </h1>
      </div>
    );
  } else
    return (
      <div
        className='main'
        style={{
          minHeight: '100%',
          paddingTop: '10vh',
          paddingBottom: '10vh',
          justifyContent: 'unset',
        }}
      >
        <div className='bread-crumbs-container'>
          <CustomizedBreadcrumbs lang={props.lang} shop single name='Cart' />
        </div>
        <EnhancedTable
          text={text}
          lang={props.lang}
          shippingPrices={props.shippingPrices}
          applyCoupon={props.applyCoupon}
          changeInfo={props.changeInfo}
          removeFromCart={props.removeFromCart}
          augmentItem={props.augmentItem}
          reduceItem={props.reduceItem}
          setShipping={props.setShipping}
          cart={props.cart}
        />
        <Button
          disabled={props.cart.shipping === 0}
          variant='contained'
          color='primary'
          size='large'
          style={{ padding: '20px 40px' }}
          onClick={onCheckout}
        >
          Checkout
        </Button>
      </div>
    );
};
