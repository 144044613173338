import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Accordion from '@material-ui/core/Accordion';
import AccordionSummary from '@material-ui/core/AccordionSummary';
import AccordionActions from '@material-ui/core/AccordionActions';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';
import text from '../utils/text';
import { NavLink } from './Navbar';

const sections = ['jewelry', 'art', 'design'];

const useStyles = makeStyles((theme) => ({
  root: {
    width: '80%',
  },
  summary: {
    margin: 0,
    padding: '0px 10px',
    borderBottom: '1px solid',
  },
  actions: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-end',
    padding: 0,
  },
  action: {
    width: '50vw !important',
    padding: '10px 0px',
    height: '50px',
    display: 'flex',
    // borderLeft: '1px solid',
    alignItems: 'center',
    // borderBottom: 'none !important',
  },
  summaryContect: {
    margin: 0,
  },
  heading: {
    fontSize: theme.typography.pxToRem(15),
    fontWeight: theme.typography.fontWeightRegular,
  },
  icon: {
    width: '1.5em',
    height: '1.5em',
  },
}));

export default function ShopAccordion({ toggleMenu, lang }) {
  const classes = useStyles();

  return (
    <div className={classes.root}>
      <Accordion elevation={0}>
        <AccordionSummary
          className={classes.summary}
          expandIcon={<ExpandMoreIcon className={classes.icon} />}
          aria-controls='panel2a-content'
          id='panel2a-header'
        >
          <NavLink
            onClick={toggleMenu}
            className={classes.summaryContect}
            to='/shop'
          >
            <li
              style={{ width: '80%', border: 'none', margin: 0 }}
              className={`navbar_item`}
            >
              <span>{text.navbar[lang].shop}</span>
            </li>
          </NavLink>
        </AccordionSummary>
        <AccordionActions className={classes.actions}>
          {sections.map((sec) => (
            <NavLink onClick={toggleMenu} key={sec} to={'/shop/' + sec}>
              <li className={`navbar_item btn-one ${classes.action}`}>
                <span>{text.navbar[lang].shopItems[sec]}</span>
              </li>
            </NavLink>
          ))}
          <NavLink onClick={toggleMenu} key={'custom made'} to={'/shop/custom'}>
            <li className={`navbar_item btn-one ${classes.action}`}>
              <span>{text.navbar[lang].shopItems.custom}</span>
            </li>
          </NavLink>
        </AccordionActions>
      </Accordion>
    </div>
  );
}
