import React, { useState } from "react";
import PropTypes from "prop-types";
import {
	Button,
	Grid,
	makeStyles,
	MenuItem,
	TextField,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "80vw",
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	input: {
		width: "100%",
	},
}));

let initialProduct = {
	nameEN: "",
	nameES: "",
	urls: [],
	price: 0,
	descriptionEN: "",
	descriptionES: "",
	materialEN: "",
	materialES: "",
	sizeEN: "",
	sizeES: "",
	type: "",
};

export default function ProductEdit({ product, onCancel, onSave, categories }) {
	let initial = initialProduct;
	if (product) {
		let { name, description, material, size, type, urls, price } = product;
		initial = {
			nameES: name.ES,
			nameEN: name.EN,
			descriptionES: description.ES,
			descriptionEN: description.EN,
			materialEN: material.EN,
			materialES: material.ES,
			sizeEN: size.EN,
			sizeES: size.ES,
			price: price,
			type,
			urls,
		};
	}

	const classes = useStyles();
	const isEdit = product !== undefined;
	const [productInfo, setProductInfo] = useState(initial);

	const handleChange = (e) => {
		setProductInfo((s) => ({ ...s, [e.target.name]: e.target.value }));
	};

	const restructureAndSave = () => {
		let {
			nameEN,
			nameES,
			descriptionEN,
			descriptionES,
			materialEN,
			materialES,
			price,
			sizeEN,
			sizeES,
			type,
			urls,
		} = productInfo;
		let info = {
			name: {
				EN: nameEN,
				ES: nameES,
			},
			description: {
				ES: descriptionES,
				EN: descriptionEN,
			},
			material: {
				ES: materialES,
				EN: materialEN,
			},
			size: {
				EN: sizeEN,
				ES: sizeES,
			},
			urls,
			type,
			price,
			id: product?.id,
		};
		onSave(info);
	};

	return (
		<Grid className={classes.container} container spacing={3}>
			<Grid item xs={12}>
				<Typography variant="h4">
					{isEdit ? "Edit Product Info" : "Add Product"}
				</Typography>
			</Grid>
			<Grid item xs={3}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Name ES"
					value={productInfo.nameES || ""}
					label="Name ES"
					name="nameES"
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Name EN"
					value={productInfo.nameEN || ""}
					label="Name EN"
					name="nameEN"
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Price"
					value={productInfo.price || ""}
					label="Price"
					name="price"
					type="number"
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					id="type"
					name="type"
					select
					className={classes.input}
					label="Choose Product Type"
					value={productInfo.type || ""}
					onChange={handleChange}
					variant="outlined"
				>
					{categories.map((option) => (
						<MenuItem key={option.label} value={option.label}>
							{option.label}
						</MenuItem>
					))}
				</TextField>
			</Grid>
			<Grid item xs={3}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Material ES"
					value={productInfo.materialES || ""}
					label="Material ES"
					name="materialES"
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Material EN"
					value={productInfo.materialEN || ""}
					label="Material EN"
					name="materialEN"
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Size EN"
					value={productInfo.sizeEN || ""}
					label="Size EN"
					name="sizeEN"
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={3}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Size ES"
					value={productInfo.sizeES || ""}
					label="Size ES"
					name="sizeES"
					onChange={handleChange}
				/>
			</Grid>

			<Grid item xs={6}>
				<TextField
					className={classes.input}
					variant="outlined"
					value={productInfo.descriptionEN || ""}
					multiline
					rows={8}
					name="descriptionEN"
					label="Description EN"
					onChange={handleChange}
					placeholder="English desciprtion"
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					className={classes.input}
					variant="outlined"
					value={productInfo.descriptionES || ""}
					multiline
					rows={8}
					name="descriptionES"
					label="Description ES"
					onChange={handleChange}
					placeholder="Spanish description"
				/>
			</Grid>
			<Grid item xs={6}></Grid>
			<Grid item xs={3}>
				<Button
					onClick={onCancel}
					className={classes.input}
					variant="contained"
				>
					Cancel
				</Button>
			</Grid>
			<Grid item xs={3}>
				<Button
					onClick={() => restructureAndSave(productInfo)}
					className={classes.input}
					variant="contained"
					color="primary"
				>
					Save
				</Button>
			</Grid>
		</Grid>
	);
}

ProductEdit.propTypes = {
	artist: PropTypes.object,
};

ProductEdit.defaultProps = {
	artist: undefined,
};
