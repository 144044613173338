import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Popper from '@material-ui/core/Popper';
import Fade from '@material-ui/core/Fade';
import text from '../utils/text';
import { NavLink } from './Navbar';

const useStyles = makeStyles((theme) => ({
  paper: {
    border: '1px solid',
    padding: theme.spacing(1),
    backgroundColor: 'white',
  },
  dropdown: {
    zIndex: '999',
  },
  action: {
    padding: '10px 0px',
    height: '50px',
    display: 'flex',
    // borderLeft: '1px solid',
    alignItems: 'center',
    color: 'black',
    // borderBottom: 'none !important',
  },
}));

const sections = ['jewelry', 'art', 'design'];

export default function ShopMenuItem({ lang, location }) {
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event, bool) => {
    setAnchorEl(bool ? event.currentTarget : null);
  };

  const open = Boolean(anchorEl);
  const id = open ? 'transitions-popper' : undefined;

  return (
    <div
      onMouseLeave={(e) => handleClick(e, false)}
      onMouseEnter={(e) => handleClick(e, true)}
    >
      <NavLink aria-describedby={id} to='/shop'>
        <li className={`navbar_item btn-one`}>
          <span>{text.navbar[lang].shop}</span>
        </li>
      </NavLink>
      <Popper
        id={id}
        className={classes.dropdown}
        open={open}
        anchorEl={anchorEl}
        transition
      >
        {({ TransitionProps }) => (
          <Fade {...TransitionProps} timeout={100}>
            <div className={classes.paper}>
              {sections.map((sec) => (
                <NavLink
                  style={{ textDecoration: 'none' }}
                  key={sec}
                  to={'/shop/' + sec}
                >
                  <li className={`navbar_item  btn-one ${classes.action}`}>
                    <span>{text.navbar[lang].shopItems[sec]}</span>
                  </li>
                </NavLink>
              ))}
              <NavLink
                style={{ textDecoration: 'none' }}
                key={'custom made'}
                to={'/shop/custom'}
              >
                <li className={`navbar_item  btn-one ${classes.action}`}>
                  <span>{text.navbar[lang].shopItems.custom}</span>
                </li>
              </NavLink>
            </div>
          </Fade>
        )}
      </Popper>
    </div>
  );
}
