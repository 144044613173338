import React from 'react';
import './ItemSingle.css';
import CustomizedBreadcrumbs from './CustomizedBreadCrumbs';
import { GlassMagnifier } from 'react-image-magnifiers';
import Slider from 'react-slick';
import { NavLink } from './Navbar';
import { Button, Grid } from '@material-ui/core';

var settings = {
  dots: false,
  // fade: true,
  autoplay: false,
  // draggable: false,
  // swipeToSlide: false,
  // swipe: false,
  touchMove: window.innerWidth > 1023,
};

export const ItemSingle = (props) => {
  const { id } = props;
  return (
    <div style={{ position: 'relative' }}>
      <div className='item-single-container'>
        <div className='bread-crumbs-container'>
          <CustomizedBreadcrumbs
            lang={props.lang}
            history={props.history}
            shop
            single
            name={props.products[id].name[props.lang]}
          />
        </div>
        <Grid
          className='item-single-grid-container'
          spacing={0}
          container
          xs={12}
        >
          <Grid style={{ marginTop: 50 }} item xs={12} sm={12} md={6} lg={6}>
            <div className='item-single-img background'>
              <Slider {...settings}>
                {props.products[id].urls.map((url, index) => (
                  <GlassMagnifier
                    key={props.products[id].name + '-' + index}
                    imageSrc={url}
                    imageAlt={props.products[id].name + '-' + index}
                    largeImageSrc={url} // Optional
                    allowOverflow={false}
                    magnifierSize={'50%'}
                    className={'zoomer-container'}
                    magnifierOffsetY={-50}
                  />
                ))}
              </Slider>
            </div>
          </Grid>
          <Grid
            style={{ marginTop: 50 }}
            spacing={2}
            container
            xs={12}
            sm={12}
            md={6}
            lg={6}
          >
            <Grid item xs={12} sm={6}>
              <h1>
                {props.products ? props.products[id].name[props.lang] : null}
              </h1>
            </Grid>
            <Grid item xs={12}>
              <h4>Description</h4>
              <p>
                {props.products
                  ? props.products[id].description[props.lang]
                  : null}
              </p>
            </Grid>
            <Grid item xs={12}>
              <h4>Material</h4>
              <p>
                {props.products
                  ? props.products[id].material[props.lang]
                  : null}
              </p>
            </Grid>
            <Grid item xs={12}>
              <h4>Size</h4>
              <p>
                {props.products ? props.products[id].size[props.lang] : null}
              </p>
            </Grid>
            <Grid item xs={12}>
              <h4>Price</h4>
              <p>
                {props.products
                  ? props.products[id].prices[props.currency]
                  : null}
              </p>
            </Grid>

            <Grid xs={6} item>
              <Button
                onClick={(e) => props.addToCart(id)}
                className='item-single-button'
                variant='contained'
                color='primary'
              >
                Add to Cart
              </Button>
            </Grid>
            <Grid xs={6} item>
              <NavLink to='/cart' className='link_decoration_none'>
                <Button
                  onClick={(e) => props.addToCart(id)}
                  variant='contained'
                  className='item-single-button'
                  color='primary'
                >
                  Checkout
                </Button>
              </NavLink>
            </Grid>
          </Grid>
        </Grid>
        {/* <div className='item-single-info'>
          <h1>{props.products ? props.products[id].name[props.lang] : null}</h1>
          <div className='item-single-desc'>
            <h4>Description</h4>
            <p>
              {props.products
                ? props.products[id].description[props.lang]
                : null}
            </p>
          </div>
          <div className='item-single-price'>
            <h4>Price</h4>
            <p>
              {props.products
                ? props.products[id].prices[props.currency]
                : null}
            </p>
          </div>
          <div className='item-single-mat'>
            <h4>Material</h4>
            <p>
              {props.products ? props.products[id].material[props.lang] : null}
            </p>
          </div>
          <div className='item-single-size'>
            <h4>Size</h4>
            <p>{props.products ? props.products[id].size[props.lang] : null}</p>
          </div>
          <h3
            onClick={(e) => props.addToCart(id)}
            className='white-hover item-single-button'
          >
            Add to Cart
          </h3>
          <NavLink
            to='/checkout'
            className='link_decoration_none item-single-checkout-link'
          >
            <h3
              className={`white-hover item-single-checkout `}
              onClick={props.onOpenCart}
            >
              Checkout
            </h3>
          </NavLink>
        </div> */}
      </div>
    </div>
  );
};
