import React, { useState } from "react";
import {
	Button,
	Grid,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";

const useStyles = makeStyles((theme) => ({
	container: {
		width: "50vw",
		maxWidth: 500,
	},
	paper: {
		backgroundColor: theme.palette.background.paper,
		border: "2px solid #000",
		boxShadow: theme.shadows[5],
		padding: theme.spacing(2, 4, 3),
	},
	input: {
		width: "100%",
	},
}));

export default function AddCoupon({ onCancel, onSave }) {
	const classes = useStyles();
	const [info, setInfo] = useState({ code: "CHANGEME", amount: 0 });

	const handleChange = (e) => {
		setInfo((s) => ({ ...s, [e.target.name]: e.target.value }));
	};

	return (
		<Grid className={classes.container} container spacing={3} xs={3}>
			<Grid item xs={12}>
				<Typography variant="h4">New Coupon</Typography>
			</Grid>
			<Grid item xs={6}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Coupon Code"
					value={info.code || ""}
					label="Coupon Code"
					name="code"
					onChange={handleChange}
				/>
			</Grid>
			<Grid item xs={6}>
				<TextField
					className={classes.input}
					variant="outlined"
					placeholder="Coupon Amount (Percent)"
					type="number"
					value={info.amount || ""}
					label="Coupon Amount (Percent)"
					name="amount"
					InputProps={{ inputProps: { min: 0, max: 100 } }}
					onChange={handleChange}
				/>
			</Grid>

			<Grid item xs={6}>
				<Button
					onClick={onCancel}
					className={classes.input}
					variant="contained"
				>
					Cancel
				</Button>
			</Grid>
			<Grid item xs={6}>
				<Button
					onClick={() => onSave(info)}
					className={classes.input}
					variant="contained"
					color="primary"
				>
					Save
				</Button>
			</Grid>
		</Grid>
	);
}
