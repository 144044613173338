import React, { useEffect, useRef } from 'react';
import Contact from './Contact';
import LazyImage from './MyImage';
import './Custom.css';
import image1 from '../images/custom-min/image1.jpg';
import image2 from '../images/custom-min/image2.jpg';
import image3 from '../images/custom-min/image3.jpg';
import image4 from '../images/custom-min/image4.jpg';
import text from '../utils/text';

export default function Custom({ isMobile, lang }) {
  const p = useRef();
  useEffect(() => {
    p.current.innerHTML = text.custom[lang].text.replace(/\n/g, '<br>');
  }, [lang]);

  return (
    <div className='main'>
      <div className='custom-text-container'>
        <h1>{text.custom[lang].title}</h1>
        <p ref={p}></p>
      </div>

      <div className='custom-images-wrapper'>
        <LazyImage
          blur
          className='custom-image'
          image={{ src: image1, alt: 'home1', placeHolder: image1 }}
        />
        <LazyImage
          blur
          className='custom-image'
          image={{ src: image2, alt: 'home1', placeHolder: image2 }}
        />
      </div>
      <div className='custom-images-wrapper'>
        <LazyImage
          blur
          className='custom-image'
          image={{ src: image3, alt: 'home1', placeHolder: image3 }}
        />
        <LazyImage
          blur
          className='custom-image'
          image={{ src: image4, alt: 'home1', placeHolder: image4 }}
        />
      </div>
      <Contact isMobile={isMobile} lang={lang} />
    </div>
  );
}
