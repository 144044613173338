import React, { useContext } from 'react';
import { useEffect } from 'react';
import { useState } from 'react';
import { ShopItem } from './ShopItem';
import './Shop.css';
import CustomizedBreadcrumbs from './CustomizedBreadCrumbs';
import { ModalContext } from '../Admin/components/SimpleModal';
import Filter from './Filter';
import Button from '@material-ui/core/Button';
import Delete from '@material-ui/icons/Delete';
import SettingsIcon from '@material-ui/icons/Settings';
import text from '../utils/text';

const returnFilterInitials = (categories, param) => {
  let cats = {};
  categories.forEach((cat) => (cats[cat.label] = true));

  if(!param) {
    return cats;
  }

  const process = (labels, arr) => {
    labels.forEach((label) => {
      if (arr[label] !== undefined) {
        arr[label] = false;
      }
    });
    return arr;
  };
  switch (param) {
    case 'jewelry':
      cats = process(['art', 'design'], cats);
      return cats;
    case 'art':
      cats = process(
        ['specks', 'earrings', 'rings', 'necklaces', 'design'],
        cats
      );
      return cats;
    case 'design':
      cats = process(['earrings', 'rings', 'necklaces', 'specks', 'art'], cats);
      return cats;
    default:
      return process(
        ['earrings', 'rings', 'necklaces', 'specks', 'art', 'design'],
        cats
      );
  }
};

const initFilter = (categories, range) => {
  let header = {
    categories: [],
  };
  Object.keys(categories).forEach((cat) => {
    if (categories[cat]) {
      return header.categories.push(cat);
    }
  });
  header.range = `$${range[0]}-$${range[1]}+`;
  header.categories = header.categories.toString();
  return {
    header,
    categories: categories,
    range,
  };
};

export const Shop = (props) => {
  const [filter, setFilter] = useState(
    initFilter(
      returnFilterInitials(props.categories[0], props.filterParam),
      [0, 300]
    )
  );
  const [productList, setProductList] = useState([]);
  const handleModal = useContext(ModalContext);

  useEffect(() => {
    setFilter(
      initFilter(
        returnFilterInitials(props.categories[0], props.filterParam),
        [0, 300]
      )
    );
  }, [props.filterParam]);

  const openFilter = () => {
    handleModal(
      <Filter
        lang={props.lang}
        categories={props.categories[0]}
        categoriesTranslater={props.categories[1]}
        initial={filter}
        onChange={(value) => {
          setFilter(value);
          handleModal();
        }}
      />
    );
  };

  const deleteFilters = () => {
    setFilter({});
  };

  useEffect(() => {
    if (filter.categories && filter.range) {
      let catNone = true;
      Object.keys(filter.categories).forEach((cat) => {
        if (filter.categories[cat]) {
          catNone = false;
          return;
        }
      });
      let filtered = Object.keys(props.products).filter((id) => {
        let cat = true;
        let price = true;
        let work = props.products[id];
        console.log(work);
        if (!catNone) {
          if (filter.categories[work.type.split('-')[0]] !== true) {
            cat = false;
          }
        }
        if (work.price) {
          let workprice = Number(work.price);
          if (
            workprice < filter.range[0] ||
            (workprice > filter.range[1] && workprice < 1000)
          ) {
            price = false;
          }
        }

        if (price && cat) {
          return true;
        }
        return false;
      });
      setProductList(filtered);
    } else {
      let categoriesStateNew = {};
      props.categories[0].forEach(
        (cat) => (categoriesStateNew[cat.label] = false)
      );
      setFilter({
        categories: categoriesStateNew,
        range: [0, 300],
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [filter]);

  return (
    <div className='main'>
      <div className='bread-crumbs-container'>
        <CustomizedBreadcrumbs lang={props.lang} shop />
      </div>

      <div className='fliter-container'>
        <div>
          <Button
            size='medium'
            onClick={openFilter}
            startIcon={<SettingsIcon />}
            variant='contained'
            color='secondary'
          >
            Filtros
          </Button>
          {filter.header && (
            <Button
              size='medium'
              style={{ marginLeft: 10 }}
              onClick={deleteFilters}
              variant='outlined'
              startIcon={<Delete />}
            >
              Eliminar filtros
            </Button>
          )}
        </div>

        {filter.header && (
          <div className='filter-chips'>
            {filter.header.categories && (
              <p>
                {text.filter.categoriesLabel[props.lang]}:{' '}
                {filter.header.categories}
              </p>
            )}
            {filter.header.range && <p>Precio: {filter.header.range}</p>}
          </div>
        )}
      </div>

      <div className={`shop_items`}>
        {productList.length === 0 && (
          <h1 key='none'>No items of this type exist</h1>
        )}
        {productList.map((id) => {
          return (
            <ShopItem
              currency={props.currency}
              addToCart={props.addToCart}
              lang={props.lang}
              key={id}
              id={id}
              name={props.products[id].name}
              description={props.products[id].description}
              type={props.products[id].type}
              price={props.products[id].price}
              prices={props.products[id].prices}
              url={props.products[id].urls[0]}
            />
          );
        })}
      </div>
    </div>
  );
};
