const text = {
  about: {
    EN: 'Andrea Alonso is a contemporary art and design project created from fire and manual work, inspired by the heritage of ancient Peru and nature. \nIt was born from the collaboration between Andrea Concepción and Diego Alonso, multidisciplinary artists who explore goldsmithing and contemporary design from a pre-Columbian vision in a dialogue from two different visions, passion for ancestral cultures, nature and manual work. The project has been operating since 2015 in its workshop in Oxapampa, Peru.\nAndrea Alonso draws inspiration from the archeology and pre-Columbian cultures of Latin America, the biodiversity of the Andes and the Amazon, as well as ancestral techniques brought to contemporary world.\nSome of the objectives as a project seeks to preserve the knowledge, techniques, concepts and iconographies of the pre-Columbian world translated into a contemporary language.\nThe workshop functions as a school where young people are trained in the art of goldsmithing, financially supporting local youth in the hope of providing them with a trade and financial independence.\nHandwork is valued as each piece is made with dedication, manual and traditional techniques. The production is done in small batches, as well as unique pieces and sculptures with a lot of dedication and love.',
    ES: 'Andrea Alonso es un proyecto de diseño y arte contemporáneo creado desde el fuego y el trabajo manual, inspirado en la herencia del antiguo Perú y la naturaleza. \nNace de la colaboración entre Andrea Concepción y Diego Alonso, artistas multidisciplinarios que exploran desde la visión precolombina la orfebrería y el diseño contemporáneo en un diálogo desde dos visiones distintas, pasión por las culturas ancestrales, la naturaleza y el trabajo manual. El proyecto funciona desde 2010 en su taller en Perú. \nAndrea Alonso tiene como fuente de inspiración la arqueología y las culturas precolombinas de Latinoamérica, la biodiversidad de los Andes y Amazonía así como técnicas ancestrales traídas al mundo actual. \nEntre los objetivos como proyecto se busca preservar el conocimiento, técnicas, conceptos e iconografías del mundo precolombino traducidos a un lenguaje contemporáneo. \nEl taller funciona como una escuela en la que se capacita a jóvenes en el arte de la orfebrería, apoyando económicamente a la juventud local con la esperanza de proveerles un oficio e independencia financiera. \nSe le da valor al trabajo hecho a mano ya que cada pieza está elaborada con dedicación, técnicas manuales y tradicionales. La producción se realiza por pequeños lotes, así como piezas únicas y esculturas con mucha dedicación y amor.',
  },
  filter: {
    priceRange: {
      EN: 'Price range',
      ES: 'Por precio',
    },
    categories: {
      EN: 'Per category',
      ES: 'Por categoria',
    },
    categoriesLabel: {
      EN: 'Categories',
      ES: 'Categorias',
    },
  },
  navbar: {
    EN: {
      home: 'HOME',
      shop: 'SHOP',
      story: 'STORY',
      contact: 'CONTACT',
      itemAdded: 'Item added!',
      shopItems: {
        jewelry: 'JEWELRY',
        art: 'ART',
        design: 'DESIGN',
        custom: 'BESPOKE',
      },
    },
    ES: {
      home: 'INICIO',
      shop: 'TIENDA',
      story: 'HISTORIA',
      contact: 'CONTACTO',
      itemAdded: 'Producto agregado!',
      shopItems: {
        jewelry: 'JOYERIA',
        art: 'ARTE',
        design: 'DISEÑO',
        custom: 'A MEDIDA',
      },
    },
  },
  contact: {
    heading: {
      EN: 'Contact us',
      ES: 'Contactanos',
    },
    message: {
      EN: 'Message recieved! We will contact you shortly.',
      ES: '¡Recibimos tu mensaje! Te responderemos pronto.',
    },
    form: {
      EN: {
        name: 'Name',
        email: 'Email',
        subject: 'Subject',
        message: 'Message',
        required: 'Required',
        emailInvalid: 'Email is invalid',
        submit: 'Submit',
      },
      ES: {
        name: 'Nombre',
        email: 'Correo',
        subject: 'Sujeto',
        message: 'Mensaje',
        required: 'Requerido',
        emailInvalid: 'Email es invalido',
        submit: 'Mandar',
      },
    },
  },
  checkout: {
    EN: {
      empty1: 'Your cart is empty, go to ',
      empty2: 'shop!',
      name: 'Name',
      price: 'Price',
      quantity: 'Quantity',
      shipping: {
        placeholder: 'Select shipping type',
        international: 'International',
        national: 'Province',
        shipping: 'Shipping rate',
      },
      cart: 'Cart',

      paymentHeader: {
        approved: 'Payment approved!',
        fail: 'Payment failed.',
        pending: 'Payment pending.',
      },
      approved:
        'Thank you for your order! We sent an email to you with all the info, we will be processing it as soon as possible!',
      fail: 'There was an error processing you payment method. Please try again, or contact us for completing your purchase manually.',
      pending:
        'Thank you for placing an order, once the payment is complete we will send you en email with the order information.',
      checkout: 'CHECKOUT',
      header1: 'Shipping Address',
      header2: 'Payment Method',

      form: {
        name: 'Name',
        surname: 'Last name',
        email: 'Email',
        street_name: 'Street Name',
        street_number: 'Number',
        city_name: 'City',
        state_name: 'State/Province/Region',
        country_name: 'Country',
        zip_code: 'Zip code',
        required: 'Required',
        emailInvalid: 'Email is invalid',
      },
    },
    ES: {
      empty1: 'Tu carrito esta vacío, ir a la ',
      empty2: 'tienda!',
      name: 'Nombre',
      price: 'Precio',
      quantity: 'Cantidad',
      shipping: {
        placeholder: 'Elige tipo de envio',
        international: 'Internacional',
        national: 'Provincias',
        shipping: 'Precio de envio',
      },
      paymentHeader: {
        approved: 'Pago aprobado!',
        fail: 'Pago fallido.',
        pending: 'Esperemamos tu pago.',
      },
      cart: 'Carrito',
      approved:
        'Gracias por tu compra! Te hemos enviado un correo, y vamos a procesar tu pedido lo antes posible!',
      fail: 'Habia un error procesando tu metodo de pago. Prueba de nuevo por favor, o puedes contactarnos para completar el pago manualmente.',
      pending:
        'Gracias por tu pedido, Al momento de completarlo te enviaremos un correo con la informacion de tu compra.',
      checkout: 'REALIZAR',
      header1: 'Direccion de Envio',
      header2: 'Modo de pago',

      form: {
        name: 'Nombre',
        surname: 'Apellido',
        email: 'Correo Electronico',
        street_name: 'Direccion',
        street_number: 'Numero',
        city_name: 'Ciudad',
        state_name: 'Provincia',
        country_name: 'Pais',
        zip_code: 'Codigo postal',
        required: 'Requerido',
        emailInvalid: 'Email es invalido',
        submit: 'Mandar',
      },
    },
  },
  shop: {
    filter: {
      EN: {
        earrings: 'Earrings',
        rings: 'Rings',
        necklaces: 'Necklaces',
        specks: 'Specs',
        art: 'Art',
        design: 'Design',
        header: 'Type selection',
      },
      ES: {
        earrings: 'Aretes',
        rings: 'Anillos',
        necklaces: 'Collares',
        specks: 'Specs',
        art: 'Arte',
        design: 'Diseño',
        header: 'Typo de joya',
      },
    },
  },
  footer: {
    EN: {
      terms: 'Terms and conditions',
      shipping: 'Shipping Policy',
      contact: 'Contact Us',
    },
    ES: {
      terms: 'Términos y condiciónes',
      shipping: 'Políticas de envío',
      contact: 'Contáctanos',
    },
  },
  shippingPolicy: {
    EN: `• The pieces are shipped from Oxapampa to Lima and the rest of the world.

        • The order will be processed between 2 to 4 business days before being shipped.
        
        • For Lima: Once the order is shipped, the estimated delivery time will be 2 to 4 business days.
        
        • For provinces within Peru, once the order is shipped, the estimated delivery time will be 5 to 10 business days.
        
        • For foreigners, once the order is shipped, the estimated delivery time will be 35 to 50 days.
        
        • The order must include the delivery address. If there is an error in the address, it will be the client's responsibility if the piece is lost or does not reach the correct destination. The client will have to bear the costs.
        
        • Delivery is included in the final price of the pieces. Jewelry and discounted pieces are subject to delivery cost.
        
        • Art pieces are subject to an additional delivery cost depending on the size, weight and volume of the chosen piece.
        
        • Business days will be considered working days, from Monday to Friday.
        
        • Due to the current situation of the Covid-19 pandemic Andrea Alonso Studio does not guarantee the estimated timeframes for the delivery of purchases.`,
    ES: `• Las piezas son enviadas desde Oxapampa a Lima y el resto del mundo.

        • La orden será procesada entre 2 a 4 días útiles antes de ser enviada.
        
        • Para Lima: Una vez enviada la orden el tiempo estimado de entrega sera de 2 a 4 días útiles.
        
        • Para provincias dentro de Perú, una vez enviada la orden el tiempo estimado de entrega será de 5 a 10 días útiles.
        
        • Para el extranjero, una vez enviada la orden el tiempo estimado de entrega será de 35 a 50 días.
        
        • La orden debe incluir la dirección de entrega. De haber algún error en la dirección será responsabilidad del cliente si la pieza se extravía o no llega al destino correcto. El cliente tendrá que correr con los gastos extra de envío de ser el caso. 
        
        • El delivery está incluido en el precio final de la piezas. Joyas y piezas con descuento están sujetas a costo de delivery.
        
        • Las piezas de arte están sujetas a un costo adicional de delivery dependiendo del tamaño, peso y volumen de la pieza elegida.
        
        • Se considerarán días útiles a los días laborables, de lunes a viernes.
        
        • Debido a la situación actual de la pandemia del Covid-19 Andrea Alonso Studio no garantiza los plazos de tiempo estimados para la entrega de las compras.
        `,
  },
  terms: {
    EN: `• Once the purchase is made, you will receive an email with the detail and the shipping address, if the address is not correct, please respond to the email with the correct delivery address, within the first 12 hours.
        \n• Shipping times are only for products in stock, does not apply to custom orders. For cases of made-to-order products, the delivery time is approximately 4 weeks.
        \n• All shipments are made from Oxapampa, Peru. There may be delays in shipments due to obstructions on the roads, or for logistical reasons due to the geographical location of Oxapampa.
        \n• All of our products are handmade. The photos displayed in the virtual store are for reference only.
        \n• Exchanges or returns are not accepted with the exception of an error in shipping, if it does not match the customer's order. In case of return, the Andrea Alonso team should be contacted as soon as possible (1 to 2 business days after delivery) to evaluate the case and see the possibility of a return if the item does not match the order.
        \n• In case of personalized pieces (Custom Made) the size will be provided by the client and is not subject to change or return after the shipment of the piece or pieces.
        `,
    ES: `• Una vez realizada la compra recibirás un email con el detalle y la dirección de envío, en caso la dirección no sea la correcta por favor responde al email con la dirección de entrega correcta, dentro de las primeras 12 horas.
\n• Los tiempos de envío son solo para los productos en stock, no aplica para pedidos personalizados. Para los casos de productos hechos a pedido el tiempo de entrega es 4 semanas aproximadamente.
\n• Todos los envíos se hacen desde Oxapampa, Perú. Pueden existir retrasos en los envíos debido a obstrucciones en las carreteras, o por cuestiones logísticas por la ubicación geográfica de Oxapampa.
\n• Todos nuestros productos son hechos a mano. Las fotos mostradas en la tienda virtual son sólo referenciales.
\n• No se aceptan cambios ni devoluciones a excepción de algún error en la pieza enviada que no concuerde con la orden del cliente. Sobre la devolución se deberá contactar al equipo de Andrea Alonso en la brevedad posible (1 a 2 días calendarios después de la entrega) para evaluar el caso y ver la posibilidad de devolución si el artículo no concuerda con la orden. 
\n• En caso de piezas personalizadas (Custom Made) la talla será brindada por el cliente y no está sujeta a cambios luego del envío de la pieza o piezas.
`,
  },
  stockAlert: {
    EN: 'We do not have more item of this type in stock.',
    ES: 'No tenemos mas de ese producto en este momento.',
  },
  custom: {
    EN: {
      title: 'Bespoke',
      text: 'At Andrea Alonso we make custom made-to-measure jewelry online or in person.\n You can come to our showroom / workshop surrounded by mountains and talk / work on the first sketches for custom projects. At Andrea Alonso we create everything from unique contemporary jewelry, for weddings and engagements, to gifts for any occasion such as birthdays or anniversaries. We also create pieces in collaboration with other designers and agents of the artistic environment.\n\nAt Andrea Alonso we offer WhatsApp consultations, in which we will see your ideas together and we can have a clearer vision of your ideas.\nAbout a week later, during a WhatsApp follow-up consultation, we will be able to share freehand sketches of your project on screen so you can see your design before it is done. Once you are happy with your design and have paid a deposit of 50% of the quote provided, you can expect to receive your bespoke piece of jewelry within a month. Customized pieces start from $ 200.',
    },
    ES: {
      title: 'Hecho a medida',
      text: 'En Andrea Alonso realizamos joyería personalizada hecha a medida en línea o en persona.\nPuedes venir a nuestra sala de exposiciones/taller rodeada de montañas y conversar/trabajar en los primeros bocetos para proyectos a medida. En Andrea Alonso creamos de todo, desde joyería contemporánea única, para bodas y compromisos, hasta regalos para cualquier ocasión como cumpleaños o aniversarios. También creamos piezas en colaboración con otros diseñadores y agentes del medio artístico.\n\nEn Andrea Alonso ofrecemos consultas  de WhatsApp, en las que veremos tus ideas juntos y podremos tener una visión más clara de tus ideas.\nAproximadamente una semana después, durante una consulta de seguimiento de Whatsapp, podremos compartir en pantalla bocetos a mano alzada de su proyecto para que pueda ver su diseño antes de realizarlo. Una vez que esté satisfecho con su diseño y haya pagado un depósito del 50% de la cotización proporcionada, puede esperar recibir su pieza de joyería a medida dentro de un mes. Las piezas personalizadas comienzan desde $200.',
    },
  },
  home: {
    EN: {
      bottom1:
        'Jewelry designed and created by hand in Oxapampa Peru, by goldsmiths ',
      bottom2: 'Andrea Concepción and Diego Alonso Carbajal',
      middle:
        'Andrea Alonso is a contemporary art and design project created from fire and manual work, inspired by the heritage of ancient Peru and nature.',
      shopButton: 'Visit our shop',
    },
    ES: {
      bottom1:
        'Joyas diseñadas y creadas a mano en Oxapampa Perú por los orfebres ',
      bottom2: 'Andrea Concepción y Diego Alonso Carbajal',
      middle:
        'Andrea Alonso es un proyecto de diseño y arte contemporáneo creado desde el fuego y el trabajo manual, inspirado en la herencia del antiguo Perú y la naturaleza.',
      shopButton: 'Visìta nuestra tienda',
    },
  },
};

export default text;
