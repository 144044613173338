// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
export const firebaseConfig = {
    apiKey: "AIzaSyBlySuwxib3VJBNyQc0aXzlSMOoyV3Uucg",
    authDomain: "andrea-alonso-web-app.firebaseapp.com",
    databaseURL: "https://andrea-alonso-web-app.firebaseio.com",
    projectId: "andrea-alonso-web-app",
    storageBucket: "andrea-alonso-web-app.appspot.com",
    messagingSenderId: "998183061732",
    appId: "1:998183061732:web:a7b67cfa59b210e1fd44f1",
    measurementId: "G-LGZ18TK7W3"
  };
  