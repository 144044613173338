import React, { useState } from 'react';
import { useEffect } from 'react';
import text from '../utils/text';

export default function OrderStatus({ initialStatus, lang, resetCart }) {
  const [paymentStatus, setPayentStatus] = useState(initialStatus);

  useEffect(() => {
    console.log('bla');
    const urlParams = new URLSearchParams(window.location.search);
    const status = urlParams.get('status');
    console.log(status);
    setPayentStatus(status);
    if (status === 'approved') {
      resetCart();
    }
  }, []);

  if (!paymentStatus) return null;

  return (
    <div className='main'>
      <h1
        style={{
          textAlign: 'center',
          width: '80%',
          marginBottom: 50,
          maxWidth: 800,
        }}
      >
        {text.checkout[lang].paymentHeader[paymentStatus]}{' '}
      </h1>
      <h2 style={{ textAlign: 'center', width: '80%', maxWidth: 800 }}>
        {text.checkout[lang][paymentStatus]}
      </h2>
    </div>
  );
}
