import { changeCurrency, getDayMonthYear } from './utils';

export const getExchange = async (db, key) => {
  try {
    let date = getDayMonthYear();
    let rates;
    let exchangeRef = db.collection('exchange').doc('latest');
    let doc = await exchangeRef.get();
    if (doc && doc.data().date !== date) {
      let res = await fetch(
        `https://openexchangerates.org/api/latest.json?app_id=${key}`
      );
      if (res.status !== 200) {
        console.log('Error getting rates from api! ' + res.status);
        return;
      }
      let data = await res.json();
      db.collection('exchange')
        .doc('latest')
        .set({ rates: data.rates, date: date });
      rates = data.rates;
      return rates;
    } else {
      rates = doc.data().rates;
      return rates;
    }
  } catch (error) {
    console.error('Error getting exchange rate', error);
  }
};

export const saveProduct = async (db, info) => {
  if (!info.id) delete info.id;
  try {
    let ref = await db.collection('products').doc(info.id);
    ref.set(info);
    if (!info.id) {
      await changeStock(db, ref.id, info.name.EN, '0');
    }
  } catch (error) {
    console.error(error);
  }
};

export const changeStock = async (db, id, name, amount) => {
  if (amount >= 0) {
    db.collection('stock').doc(id).set({
      name: name,
      stock: amount,
    });
  }
};

export const deleteProduct = async (db, id) => {
  try {
    await db.collection('products').doc(id).delete();
  } catch (error) {
    console.error(error);
  }
};

export const getCategories = async (db) => {
  let doc = await db.collection('generalData').doc('productCategories').get();

  if (doc.exists) {
    const categories = doc
      .data()
      .data.map((cat, index) => ({ key: index, label: cat }));
    return categories;
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');

    return [];
  }
};

export const getShippingPrices = async (db) => {
  let doc = await db.collection('generalData').doc('shippingPrices').get();

  if (doc.exists) {
    const prices = doc.data();
    return prices;
  } else {
    // doc.data() will be undefined in this case
    console.log('No such document!');

    return [];
  }
};

export const getStock = async (db) => {
  try {
    let stockObject = {};
    let stockRef = db.collection('stock');
    let snapshot = await stockRef.get();
    snapshot.forEach((doc) => {
      stockObject[doc.id] = {
        name: doc.data().name,
        stock: doc.data().stock,
      };
    });
    return stockObject;
  } catch (error) {
    console.error(error);
    return {};
  }
};

export const getCoupons = async (db) => {
  try {
    let snapshot = await db.collection('coupons').get();
    let coupons = snapshot.docs.map((doc) => doc.data());
    return coupons;
  } catch (error) {
    console.error(error);
    return [];
  }
};

export const addCoupon = async (db, coupon) => {
  try {
    await db.collection('coupons').doc(`${coupon.id}`).set(coupon);
  } catch (error) {
    console.error(error);
  }
};

export const deleteCoupon = async (db, id) => {
  try {
    await db.collection('coupons').doc(`${id}`).delete();
  } catch (error) {
    console.error(error);
  }
};

export const saveApprovedOrder = async (db, order) => {
  try {
    await db.collection('orders').add(order);
  } catch (error) {
    console.error(error);
  }
};

export const fillProducts = async (db, rates) => {
  try {
    let productsObject = {};
    let productsRef = db.collection('products');
    let snapshot = await productsRef.get();
    snapshot.forEach((doc) => {
      let price = Number(doc.data().price);
      productsObject[doc.id] = {
        urls: doc.data().urls,
        name: doc.data().name,
        description: doc.data().description,
        material: doc.data().material,
        type: doc.data().type,
        size: doc.data().size,
        price: price,
        prices: {
          USD: `$${price}`,
          EUR: changeCurrency(price, 'EUR', rates),
          PEN: changeCurrency(price, 'PEN', rates),
        },
      };
    });
    return productsObject;
  } catch (error) {
    console.error('Error getting products', error);
    return {};
  }
};

export const getOrders = async (db) => {
  try {
    let ordersRef = db.collection('orders');
    let snapshot = await ordersRef.get();
    const orders = snapshot.docs.map((doc) => doc.data());
    console.log(orders);
    return orders;
  } catch (error) {
    console.error('Error getting orders', error);
    return {};
  }
};

export const deleteOrder = async (db, id) => {
  try {
    await db.collection('orders').doc(id).delete();
  } catch (error) {
    console.error(error);
  }
};
