import React, { useState } from 'react';
import { Link } from '@reach/router';
import './Navbar.css';
import { ReactComponent as ReactLogo } from '../images/logo-strong.svg';
import { ReactComponent as ReactLogoMobile } from '../images/logo-notext.svg';
import cart from '../images/cart.svg';
import { LangSwitch } from './LangSwitch';
import { Cart } from './Cart';
import { SelectCurrency } from './SelectCurrency';
import { default as navText } from '../utils/text';
import ShopAccordion from './ShopAccordion';
import ShopMenuItem from './ShopMenuItem';

const text = navText.navbar;

export const NavLink = (props) => (
  <Link
    {...props}
    getProps={({ isCurrent }) => {
      // the object returned here is passed to the
      // anchor element's props
      return {
        className: isCurrent ? 'selected' : '',
      };
    }}
  />
);

export const Navbar = (props) => {
  const [menuState, setMenuState] = useState('');
  const [cartState, setCartState] = useState('');
  const [backDivState, setBackDivState] = useState('');

  const onOpenMenu = () => {
    // alert('bla');
    if (menuState === '') {
      setMenuState('open');
      setBackDivState('open');
      setCartState('');
    } else {
      setMenuState('');
      setBackDivState('');
    }
  };

  const onOpenCart = () => {
    if (cartState === '') {
      setCartState('open');
      setBackDivState('open');
      setMenuState('');
    } else {
      setCartState('');
      setBackDivState('');
    }
  };

  return (
    <div className='navbar' id='navbar'>
      <div className='navbar-wrapper'>
        {!props.isMobile ? <LangSwitch changeLang={props.changeLang} /> : null}
        <div className={menuState} onClick={onOpenMenu} id='nav-icon3'>
          <span></span>
          <span></span>
          <span></span>
          <span></span>
        </div>
        {props.isMobile ? (
          <NavLink to='/home'>
            <ReactLogoMobile className='logo' alt='logo' />
          </NavLink>
        ) : null}
        <div
          onClick={(e) => {
            setBackDivState('');
            setCartState('');
            setMenuState('');
          }}
          className={`back-div ${backDivState}`}
        />
        <ul className={`navbar_itemlist ${menuState}`}>
          {props.isMobile ? <LangSwitch changeLang={props.changeLang} /> : null}
          {props.isMobile ? (
            <SelectCurrency
              isMobile={props.isMobile}
              handleSelect={props.selectCurrency}
              currency={props.currency}
            />
          ) : null}
          <NavLink onClick={onOpenMenu} to='/home'>
            <li className={`navbar_item btn-one`}>
              <span>{text[props.lang].home}</span>
            </li>
          </NavLink>

          {props.isMobile ? (
            <ShopAccordion lang={props.lang} toggleMenu={onOpenMenu} />
          ) : (
            <ShopMenuItem text={text} lang={props.lang} />
          )}

          {!props.isMobile ? (
            <NavLink onClick={onOpenMenu} key='logolink' to='/home'>
              <ReactLogo className='logo' alt='logo' />
            </NavLink>
          ) : null}
          <NavLink onClick={onOpenMenu} to='/story'>
            <li className={`navbar_item btn-one`}>
              <span>{text[props.lang].story}</span>
            </li>
          </NavLink>
          <NavLink onClick={onOpenMenu} key='/contact' to='/contact'>
            <li className={`navbar_item btn-one`}>
              <span>{text[props.lang].contact}</span>
            </li>
          </NavLink>
        </ul>
        <Cart
          changeInfo={props.changeInfo}
          currency={props.currency}
          augmentItem={props.augmentItem}
          reduceItem={props.reduceItem}
          removeFromCart={props.removeFromCart}
          lang={props.lang}
          cart={props.cart}
          cartState={cartState}
          onOpenCart={onOpenCart}
        />
        {!props.isMobile ? (
          <SelectCurrency
            isMobile={props.isMobile}
            handleSelect={props.selectCurrency}
            currency={props.currency}
          />
        ) : null}
        <div className='cart-icon'>
          <div className='cart-icon-pointer' onClick={(e) => onOpenCart()}>
            <img className='cart-icon-image' src={cart} alt='cart-icon' />
            <div className='cart-icon-amount'>{props.cart.amount}</div>
          </div>
          <span className='item-added-tooltip'>
            {text[props.lang].itemAdded}
          </span>
        </div>
      </div>
    </div>
  );
};
