import React from 'react';
import Typography from '@material-ui/core/Typography';
import MercadoPago from './MercadoPago';
import ReactDOM from 'react-dom';
import text from '../utils/text';
import { saveApprovedOrder } from '../utils/dbRequests';
import { navigate } from '@reach/router';

let PayPalButton = window.paypal.Buttons.driver('react', { React, ReactDOM });

export default function PaymentForm({
  cart,
  currency,
  shippingInfo,
  lang,
  db,
  closeModal,
}) {
  const createOrder = (data, actions) => {
    return actions.order.create({
      purchase_units: [
        {
          amount: {
            value: cart.total,
          },
        },
      ],
    });
  };

  const onApprove = (data, actions) => {
    return actions.order.capture().then((res) => {
      actions.order.get().then(async (orderInfo) => {
        const items = Object.keys(cart.items).map((id) => {
          const {
            amount: quantity,
            price: unit_price,
            name: title,
          } = cart.items[id];
          return {
            quantity,
            title: title.EN,
            unit_price,
          };
        });
        let shippingAddressPaypal = {};
        try {
          shippingAddressPaypal = orderInfo.purchase_units[0].shipping;
        } catch (error) {}

        await saveApprovedOrder(db, {
          items,
          client: {
            name: shippingInfo.name,
            surname: shippingInfo.surname,
            email: shippingInfo.email,
          },
          shipping: cart.shipping,
          shippingAddress: shippingInfo,
          shippingAddressPaypal,
          total: cart.total,
          id: data.orderID,
          date: new Date().toISOString(),
          type: 'paypal',
        });
        navigate('/payment-status?status=approved');
        closeModal();
      });
    });
  };

  return (
    <React.Fragment>
      <Typography variant='h6' gutterBottom>
        {text.checkout[lang].header2}
      </Typography>
      <div className='payment-buttons-container'>
        <MercadoPago
          shippingInfo={shippingInfo}
          cart={cart}
          currency={currency}
        />
        <div style={{ width: 200, marginTop: 10 }}>
          <PayPalButton
            style={{
              layout: 'horizontal',
              color: 'black',
              shape: 'rect',
              label: 'checkout',
            }}
            createOrder={(data, actions) => createOrder(data, actions)}
            onApprove={(data, actions) => onApprove(data, actions)}
          />
        </div>
      </div>
    </React.Fragment>
  );
}
