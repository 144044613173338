import React from 'react';
import Select from 'react-select';

export const SelectCurrency = ({ isMobile, currency, handleSelect }) => {
  const options = [
    { value: 'USD', label: isMobile ? '$' : 'USD' },
    { value: 'EUR', label: isMobile ? '€' : 'EUR' },
    { value: 'PEN', label: isMobile ? 'S/' : 'PEN' },
  ];

  let customStyles = {
    container: (provided, state) => ({
      ...provided,
      position: isMobile ? 'absolute' : 'relative',
      right: isMobile ? '25vmin' : '50px',
      width: isMobile ? '15vmin' : '120px',
      fontSize: isMobile ? '3.5vmin' : '18px',
      fontWeight: '600',
      top: isMobile ? '3vmin' : 'unset',
    }),
    dropdownIndicator: (provided) => ({
      ...provided,
      padding: isMobile ? 2 : 4,
    }),
    control: (provided) => ({
      ...provided,
      minHeight: 24,
    }),
    valueContainer: (provided) => ({
      ...provided,
      padding: isMobile ? '2px 4px' : '2px 8px',
    }),
    menu: (provided) => ({
      ...provided,
      zIndex: 999,
    }),
  };
  return (
    <Select
      className='react-select-container'
      classNamePrefix='react-select'
      value={currency}
      onChange={handleSelect}
      options={options}
      styles={customStyles}
      theme={(provided) => ({
        ...provided,
        colors: {
          ...provided.colors,
          primary: 'rgb(53, 55, 56)',
        },
      })}
    />
  );
};
