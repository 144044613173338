/* eslint-disable react/display-name */
import {
	Button,
	Grid,
	IconButton,
	makeStyles,
	TextField,
	Typography,
} from "@material-ui/core";
import React, { useContext, useEffect, useState } from "react";
import DataTable from "./components/DataTable";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import DeleteIcon from "@material-ui/icons/Delete";
import { ModalContext } from "./components/SimpleModal";
import Images from "./components/ImageUploader";
import ProductEdit from "./ProductEdit";
import {
	changeStock,
	deleteProduct,
	fillProducts,
	getCategories,
	getStock,
	saveProduct,
} from "../utils/dbRequests";

const useStyles = makeStyles((theme) => ({
	root: {
		display: "flex",
		flexGrow: 1,

		flexDirection: "column",
		alignContent: "center",
		justifyContent: "flex-start",
	},
	button: {
		maxWidth: 200,
	},
}));

const StockChanger = ({ name, id, amount, onSave }) => {
	const [value, setValue] = useState(amount);
	const save = () => {
		onSave(id, name, value);
	};
	return (
		<Grid container xs={12}>
			<Grid item>
				<div className="row quantity">
					<TextField
						type="number"
						onChange={(e) => setValue(e.target.value)}
						placeholder={amount}
					/>
				</div>
			</Grid>
			<Grid onClick={save} item xs={12}>
				<Button type="contained" color="primary">
					Save
				</Button>
			</Grid>
		</Grid>
	);
};

export default function Products({ db, storage, changeRates }) {
	const classes = useStyles();
	const handleModal = useContext(ModalContext);
	const [products, setProducts] = useState([]);
	const [productCategories, setProductCategories] = useState([]);
	const [stock, setStock] = useState();

	const init = async () => {
		setProductCategories(await getCategories(db));
		refresh();
	};

	useEffect(() => {
		init();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	const updateStock = async (id, name, amount) => {
		await changeStock(db, id, name, amount);
		refresh();
		handleModal();
	};

	const openStockChanger = (product) => {
		let amount = stock[product.id].stock;
		let name = product.name.EN;
		let id = product.id;
		handleModal(
			<StockChanger amount={amount} id={id} name={name} onSave={updateStock} />
		);
	};

	const openGallery = (product) => {
		handleModal(
			<Images
				path={`${product.name.EN.toLowerCase().replace(" ", "_")}_${
					product.id
				}/photos`}
				images={product.urls}
				onChange={(images) => onGalleryChange({ ...product, urls: images })}
				title="Product Photos"
				storage={storage}
			/>
		);
	};

	const columns = [
		{
			field: "name",
			headerName: "Name",
			width: 250,
			renderCell: (params) => (
				<>
					<Button variant="text" size="small">
						{params.value.EN}
					</Button>
				</>
			),
		},
		{
			field: "type",
			headerName: "Product Type",
			type: "string",
			width: 180,
		},
		{
			field: "price",
			headerName: "Price",
			type: "string",
			width: 100,
			renderCell: (params) => <>{params.value}$</>,
		},
		{
			field: "urls",
			headerName: "Photos",
			sortable: false,
			width: 120,
			renderCell: (params) => (
				<Button
					onClick={() => openGallery(params.row)}
					variant="outlined"
					size="small"
				>
					{params.value.length} Photos
				</Button>
			),
		},
		{
			field: "description",
			headerName: "Stock",
			sortable: false,
			width: 120,
			renderCell: (params) => (
				<Button
					onClick={() => openStockChanger(params.row)}
					variant="outlined"
					size="small"
				>
					{stock[params.row.id].stock}
				</Button>
			),
		},

		{
			field: "edit",
			headerName: "Edit",
			sortable: false,
			width: 100,
			renderCell: (params) => (
				<IconButton
					variant="contained"
					color="primary"
					size="small"
					onClick={() => openAdd(products[params.value])}
					style={{ marginLeft: 16 }}
				>
					<EditIcon />
				</IconButton>
			),
		},
		{
			field: "id",
			headerName: "Delete",
			sortable: false,
			width: 100,
			renderCell: (params) => (
				<IconButton
					variant="contained"
					color="primary"
					size="small"
					onClick={() => onDelete(params.value)}
					style={{ marginLeft: 16 }}
				>
					<DeleteIcon />
				</IconButton>
			),
		},
	];

	const refresh = async () => {
		let processed = await fillProducts(db, changeRates);
		processed = Object.keys(processed).map((product) => ({
			...processed[product],
			id: product,
		}));
		processed = processed.map((artist, index) => {
			return { ...artist, edit: index };
		});
		let newStock = await getStock(db);
		setStock(newStock);
		setProducts(processed);
	};

	const onSave = async (info) => {
		await saveProduct(db, info);
		handleModal();
		refresh();
	};

	const onGalleryChange = async (info) => {
		await saveProduct(db, info);
		refresh();
	};

	const onDelete = async (id) => {
		await deleteProduct(db, id);
		refresh();
	};

	const openAdd = (product) => {
		handleModal(
			<ProductEdit
				categories={productCategories}
				product={product}
				onSave={onSave}
				onCancel={() => handleModal()}
			/>
		);
	};

	return (
		<div>
			<Typography variant="h3">Products</Typography>

			<div className={classes.root}>
				<Button
					onClick={() => openAdd()}
					className={classes.button}
					startIcon={<AddIcon />}
					variant="outlined"
				>
					Add Product
				</Button>
				<DataTable rows={products} columns={columns} />
			</div>
		</div>
	);
}
