import React, { useEffect, useState } from "react";
import "./App.css";

import firebase from "firebase/app";
import "firebase/firestore";
import "firebase/analytics";
import "firebase/storage";
import "firebase/auth";
import logo from "./images/logo.svg";
import Admin from "./Admin/Admin";
import { Redirect, Router } from "@reach/router";
import MainView from "./MainView";
import { firebaseConfig } from "./credentials";

import {
	fillProducts,
	getCategories,
	getExchange,
	getShippingPrices,
	getStock,
} from "./utils/dbRequests";
import { EX_API_KEY } from "./exchangeKey";
import GeneralSettings from "./Admin/GeneralSettings";
import Products from "./Admin/Products";
import Coupons from "./Admin/Coupons";
import { ScrollToTop } from "./components/ScrollToTop";
import Orders from "./Admin/Orders";

firebase.initializeApp(firebaseConfig);
firebase.analytics();

const auth = firebase.auth();
const db = firebase.firestore();
const storage = firebase.storage();

const createCateogryLangMapper = (categories) => {
	let mapper = {};
	let processed = categories.map((cat) => {
		const splitted = cat.label.split("-");
		mapper[splitted[0]] = { EN: splitted[0], ES: splitted[1] };
		return { key: cat.key, label: splitted[0] };
	});
	return [processed, mapper];
};

export { storage, db, firebase };

firebase
	.firestore()
	.enablePersistence()
	.catch(function (err) {
		if (err.code === "failed-precondition") {
			// Multiple tabs open, persistence can only be enabled
			// in one tab at a a time.
			// ...
		} else if (err.code === "unimplemented") {
			// The current browser does not support all of the
			// features required to enable persistence
			// ...
			console.log("no persistence implemented");
		}
	});

function App(props) {
	const [products, setProducts] = useState(undefined);
	const [stock, setStock] = useState(undefined);
	const [changeRates, setChangeRates] = useState(undefined);
	const [productCategories, setProductCategories] = useState(undefined);
	const [loaded, setLoaded] = useState(false);
	const [shippingPrices, setShippingPrices] = useState({});

	const init = async () => {
		try {
			let rates = await getExchange(db, EX_API_KEY);
			setChangeRates(rates);
			setProducts(await fillProducts(db, rates));

			setProductCategories(createCateogryLangMapper(await getCategories(db)));
			setStock(await getStock(db));
			setShippingPrices(await getShippingPrices(db));

			setLoaded(true);
		} catch (error) {
			console.error(error);
			setLoaded(false);
		}
	};

	useEffect(() => {
		init();
	}, []);

	if (loaded) {
		return (
			<div className="App">
				<Router>
					<Redirect noThrow={true} from="/" to="home" />
					<ScrollToTop path="/">
						<MainView
							db={db}
							stock={stock}
							products={products}
							changeRates={changeRates}
							productCategories={productCategories}
							shippingPrices={shippingPrices}
							path="/*"
						/>
					</ScrollToTop>

					<Admin auth={auth} path="admin">
						<GeneralSettings path="/" db={db} />
						<Products
							path="/products"
							db={db}
							storage={storage}
							changeRates={changeRates}
						/>
						<Orders path="/orders" db={db} />
						<Coupons path="/coupons" db={db} />
					</Admin>
				</Router>
			</div>
		);
	} else {
		return (
			<div
				className="App"
				style={{
					display: "flex",
					flexDirection: "column",
					justifyContent: "center",
					alignItems: "center",
				}}
			>
				<img width={200} src={logo} alt="logo" />
				<div className="lds-spinner">
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
					<div></div>
				</div>
				<h3 style={{ marginTop: "2vmin", width: "100%", textAlign: "center" }}>
					LOADING
				</h3>
			</div>
		);
	}
}

export default App;
