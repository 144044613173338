import React from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/opacity.css';
import Loader from './Loader';
import 'react-lazy-load-image-component/src/effects/blur.css';

const MyImage = ({ image, className, blur }) => (
  <LazyLoadImage
    className={className}
    alt={image.alt}
    effect={'blur'}
    height={image.height}
    src={image.src} // use normal <img> attributes as props
    width={image.width}
    placeholder={<Loader />}
  />
);

export default MyImage;
