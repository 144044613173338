import React, { useEffect, useState } from 'react';
import './MercadoPago.css';

const paymentServerUrlBase =
  'https://us-central1-andrea-alonso-web-app.cloudfunctions.net';

const selfUrl = 'https://andrealonso.com';

export default function MercadoPagoButton({ cart, currency, shippingInfo }) {
  const [preference, setPreference] = useState(null);

  useEffect(() => {
    // luego de montarse el componente, le pedimos al backend el preferenceId
    if (cart) {
      const processedItems = Object.keys(cart.items).map((itemId) => {
        const item = cart.items[itemId];
        return {
          title: item.name.EN,
          unit_price: item.price,
          quantity: item.amount,
          currency_id: 'USD',
        };
      });
      processedItems.push({
        title: 'Shipping',
        unit_price: cart.shipping,
        quantity: 1,
        currency_id: 'USD',
      });

      const additional_info = JSON.stringify({
        total: cart.total,
      });

      let processedShippingInfo = {
        ...shippingInfo,
        street_number: Number(shippingInfo.street_number),
      };
      fetch(paymentServerUrlBase + '/order', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          additional_info,
          items: processedItems,
          shipments: {
            receiver_address: processedShippingInfo,
          },
          payer: processedShippingInfo,
          back_urls: {
            success: selfUrl + '/payment-status',
            failure: selfUrl + '/payment-status',
            pending: selfUrl + '/payment-status',
          },
          auto_return: 'approved',
        }),
      }).then((order) => {
        order.json().then((res) => {
          console.log(res);
          setPreference(res);
        });
      });
    }
  }, [cart, currency]);

  useEffect(() => {
    if (preference) {
      // Add the SDK credentials
      const mp = new window.MercadoPago(
        'APP_USR-f7349b1c-1fb1-4eb0-9246-72e867a2b22e',
        {
          locale: 'es-AR',
        }
      );

      // Initialize the checkout
      mp.checkout({
        preference: {
          id: preference.id,
          init_point: preference.init_point,
        },
      });
    }
  }, [preference]);

  const isReady = preference?.init_point;

  return (
    <a
      style={{
        opacity: isReady ? 1 : 0.5,
        pointerEvents: isReady ? 'all' : 'none',
      }}
      href={preference?.init_point}
      className='mp-pay-button'
      rel='noreferrer'
      target='_blank'
    >
      Mercado Pago
    </a>
  );
}
