import React, { useEffect, useState } from 'react';
import './Cart.css';
import { CartItem } from './CartItem';
import { Link } from '@reach/router';
import { changeCurrency } from '../utils/utils';
import text from '../utils/text';

export const Cart = (props) => {
  // const [total,setTotal] = useState(0);
  const [items, setItems] = useState(null);

  useEffect(() => {
    // let totalNew = 0;
    if (props.cart) {
      let newItems = Object.keys(props.cart.items).map((id) => {
        // totalNew = totalNew + props.cart.items[id].price*props.cart.items[id].amount;
        return (
          <CartItem
            key={id}
            currency={props.currency}
            augmentItem={props.augmentItem}
            reduceItem={props.reduceItem}
            removeFromCart={props.removeFromCart}
            src={props.cart.items[id].urls[0]}
            name={props.cart.items[id].name[props.lang]}
            price={props.cart.items[id].price}
            prices={props.cart.items[id].prices}
            id={id}
            lang={props.lang}
            quantity={props.cart.items[id].amount}
          />
        );
      });
      // setTotal(totalNew);
      setItems(newItems);
    }
    // eslint-disable-next-line
  }, [props.cart, props.lang, props.currency]);

  return (
    <div className={`cart column full center ${props.cartState}`}>
      <div
        className='column '
        style={{
          position: 'absolute',
          top: '2vmin',
          overflow: 'scroll',
          width: '90%',
          height: '60%',
          marginBottom: '2vmin',
          alignItems: 'center',
          justifyContent: props.cart.amount === 0 ? 'center' : 'flex-start',
        }}
      >
        {items}
        {props.cart.amount === 0 ? (
          <h3>
            {text.checkout[props.lang].empty1}
            <Link to='/shop'>{text.checkout[props.lang].empty2}</Link>
          </h3>
        ) : null}
      </div>
      <div
        className='row'
        style={{ width: '70%', position: 'absolute', top: '65%' }}
      >
        <h3>Total:</h3>
        <div className='spacer' />
        <h3>
          {changeCurrency(
            props.cart.total,
            props.changeInfo.currency.value,
            props.changeInfo.changeRates
          )}
        </h3>
      </div>
      <Link
        to='/cart'
        className='link_decoration_none'
        style={{
          position: 'absolute',
          top: '70%',
          width: '75%',
          pointerEvents: props.cart.amount === 0 ? 'none' : 'all',
        }}
      >
        <button
          className={`white-hover center ${
            props.cart.amount === 0 ? 'empty' : ''
          }`}
          onClick={props.onOpenCart}
        >
          {text.checkout[props.lang].checkout}
        </button>
      </Link>
    </div>
  );
};
