import React from 'react';
import './Footer.css';
import MyImage from './MyImage';
import logoWhite from '../images/logo-strong-white.svg';
import inst from '../images/instagram.svg';
import face from '../images/facebook.svg';
import text from '../utils/text';
import { NavLink } from './Navbar';

export default function Footer(props) {
  // console.log(facebook)
  return (
    <div className='footer'>
      <div className='footer-links'>
        <NavLink to='/shipping-policy'>
          {text.footer[props.lang].shipping}
        </NavLink>
        <NavLink to='/terms-and-conditions'>
          {text.footer[props.lang].terms}
        </NavLink>

        <NavLink to='/contact'>{text.footer[props.lang].contact}</NavLink>
      </div>
      <div className='footer-spacer' />
      <div className='footer-img-container'>
        <MyImage
          blur
          className='footer-img'
          image={{
            width: '100%',
            height: '100%',
            src: logoWhite,
            alt: 'logo-footer',
            placeHolder: logoWhite,
          }}
        />
      </div>
      <div className='footer-spacer' />

      <div className='socialmedia'>
        <a
          target='_blank'
          style={{ marginRight: 20 }}
          rel='noopener noreferrer'
          href='https://www.instagram.com/andrea.alonso.studio'
        >
          <img alt='instagram' width='30px' src={inst} />
        </a>
        <a
          target='_blank'
          rel='noopener noreferrer'
          href='https://www.facebook.com/andrea.alonso.studio/'
        >
          <img alt='facebook' width='30px' src={face} />
        </a>
      </div>
    </div>
  );
}
