import React from 'react';
import studioImg from '../images/about-min/image1.jpg';
import './About.css';
import text from '../utils/text';
import { useEffect } from 'react';
import { useRef } from 'react';
import MyImage from './MyImage';

export const About = (props) => {
  const p = useRef();
  useEffect(() => {
    p.current.innerHTML = text.about[props.lang].replace(/\n/g, '<br>');
  }, [props.lang]);

  return (
    <div className='main'>
      <MyImage
        className='background about-img'
        image={{ src: studioImg, alt: 'home1' }}
      />

      <p className='about-text' ref={p}></p>
      <iframe
        title='story-video'
        className='home-video'
        src='https://player.vimeo.com/video/347957987?byline=0'
        width={1000}
        height={500}
        frameBorder='0'
        allow='autoplay; fullscreen'
        allowFullScreen
      ></iframe>
    </div>
  );
};
