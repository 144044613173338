import React from 'react';
import cancel from '../images/cancel.svg';
import { AddRemoveItem } from './AddRemoveItem';
import text from '../utils/text';

export const CartItem=props=>{
    

    return(
        <div className='cart-item row center'>
            <div style={{backgroundImage: `url(${props.src})`}} className='cart-item-img' />
            <img onClick={e=>props.removeFromCart(props.id)} className='cancel-item' src={cancel} alt="cancel item"/>
            <div className='spacer'/>
            <div className='column cart-item-details' >
                <p>{props.name}</p>
                <p>{text.checkout[props.lang].price}: {props.prices[props.currency.value]}</p>
                <AddRemoveItem quantity={props.quantity} id={props.id} augmentItem={props.augmentItem} reduceItem={props.reduceItem}    />
            </div>
        </div>
    )
}