import React from 'react';
import './LangSwitch.css';

export const LangSwitch =props=>(
    <label className="switch_join">
        <input onChange={e=>props.changeLang(e)} type="checkbox" style={{opacity: 0}}/>
        <span className="slider_join">
            <div className="lang">EN</div>
            <div className="lang">ES</div>
        </span>
    </label>
)